export default [
  {
    path: '/tai-lieu-ky-so/danh-sach',
    component: () => import('@/views/management-business/manager-digital-doc/pages/List.vue'),
    name: 'digitally-signed',
    meta: {
      pageTitle: 'Tài liệu ký số',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Tài liệu ký số',
          to: '/tai-lieu-ky-so/danh-sach',
        },
      ],
    },
  },
]
