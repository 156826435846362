/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataValidUser: [],
  dataNoValidUser: [],
  dataTooltip: [],
  dataListOccupation: [],
  dataOccupationChildren: [],
  dataOccupationUpdated: [],
})

const getters = {
  dataValidUser: state => state.dataValidUser,
  dataNoValidUser: state => state.dataNoValidUser,
  dataTooltip: state => state.dataTooltip,
  dataListOccupation: state => state.dataListOccupation,
  dataOccupationChildren: state => state.dataOccupationChildren,
  dataOccupationUpdated: state => state.dataOccupationUpdated,
}

const mutations = {
  DATA_VALID_USER(state, data) {
    state.dataValidUser = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.dataNoValidUser = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
  SET_DATA_LIST_OCCUPATION(state, data) {
    state.dataListOccupation = data.data
  },
  SET_DATA_OCCUPATION_CHILDREN(state, data) {
    state.dataOccupationChildren = data.data
  },
  SET_DATA_BUSINESS_UPDATED(state, data) {
    state.dataOccupationUpdated = data.data
  },
}

const actions = {
  // Gọi danh sách danh mục nghề nghiệp cần thêm mới
  async doFetchOccupationUpdated({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OCCUPATION_TREEVIEW_UPDATED, { params: payload })
      commit('SET_DATA_BUSINESS_UPDATED', data)
    } catch (e) {
      return null
    }
  },

  // Gọi dánh sách danh mục nghề nghiệp cha
  async doFetchListOccupation({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OCCUPATION_TREEVIEW_ROOT, { params: payload })
      commit('SET_DATA_LIST_OCCUPATION', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách danh mục nghề nghiệp con
  async doFetchOccupationChildren({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OCCUPATION_TREEVIEW_CHILDREN, { params: payload })
      commit('SET_DATA_OCCUPATION_CHILDREN', data)
    } catch (e) {
      return null
    }
  },

  // Download tập tin nội dung danh mục nghề nghiệp về máy
  async downloadFileOccupation() {
    try {
      axiosApiInstance({
        url: '/TypeOfProfession/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DanhMucNgheNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Download tập tin mẫu danh mục nghề nghiệp về máy
  async downloadExampleFileOccupation() {
    try {
      axiosApiInstance({
        url: '/TypeOfProfession/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DanhMucNgheNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Thêm danh mục nghề nghiệp từ file excel
  async getApiExcelOccupation({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_FILE_OCCUPATION, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
