export default [
  // Quản lý thiết bị kiểm định
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi-kiem-dinh/danh-sach',
    name: 'equipment-management',
    component: () => import('@/views/occupational-safety-health/equipment-management/pages/List.vue'),
    meta: {
      parent: 'equipment-management',
      pageTitle: 'Quản lý thiết bị kiểm định',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Danh sách thiết bị kiểm định',
          active: true,
        },
      ],
    },
  },

  // Thêm file Quản lý thiết bị kiểm định
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi-kiem-dinh/them-tu-tep',
    name: 'import-equipment-management',
    component: () => import('@/views/occupational-safety-health/equipment-management/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'equipment-management',
      pageTitle: 'Quản lý thiết bị kiểm định',
      breadcrumb: [
        {
          text: 'Danh sách thiết bị kiểm định',
          to: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi-kiem-dinh/danh-sach',
        },
        {
          text: 'Thêm file thiết bị kiểm định',
          active: true,
        },
      ],
    },
  },

  // Quản lý thiết bị doanh nghiệp
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi/danh-sach',
    name: 'manage-equip',
    component: () => import('@/views/occupational-safety-health/manage-equip/pages/List.vue'),
    meta: {
      parent: 'manage-equip',
      pageTitle: 'Quản lý thiết bị',
      breadcrumb: [
        {
          text: 'Danh sách thiết bị',
        },
      ],
    },
  },

  // Thêm file Quản lý thiết bị doanh nghiệp
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi/them-tu-tep',
    name: 'import-equipment-business',
    component: () => import('@/views/occupational-safety-health/manage-equip/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'manage-equip',
      pageTitle: 'Quản lý thiết bị',
      breadcrumb: [
        {
          text: 'Danh sách thiết bị',
          to: '/an-toan-ve-sinh-lao-dong/quan-li-thiet-bi/danh-sach',
        },
        {
          text: 'Thêm file thiết bị',
          active: true,
        },
      ],
    },
  },

  // Quản lý kiểm định viên
  {
    path: '/an-toan-ve-sinh-lao-dong/kiem-dinh-vien/danh-sach',
    name: 'inspector',
    component: () => import('@/views/occupational-safety-health/inspector/pages/List.vue'),
    meta: {
      parent: 'inspector',
      pageTitle: 'Quản lý kiểm định viên',
      breadcrumb: [
        {
          text: 'Kiểm định viên',
        },
      ],
    },
  },

  // Thêm file Quản lý kiểm định viên
  {
    path: '/an-toan-ve-sinh-lao-dong/kiem-dinh-vien/them-tu-tep',
    name: 'import-inspector',
    component: () => import('@/views/occupational-safety-health/inspector/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'inspector',
      pageTitle: 'Quản lý kiểm định viên',
      breadcrumb: [
        {
          text: 'Danh sách kiểm định viên',
          to: '/an-toan-ve-sinh-lao-dong/kiem-dinh-vien/danh-sach',
        },
        {
          text: 'Thêm file kiểm định viên',
          active: true,
        },
      ],
    },
  },

  // Chi phí ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/chi-phi-atvsld',
    name: 'cost-atvsld',
    component: () => import('@/views/occupational-safety-health/cost/pages/List.vue'),
    meta: {
      parent: 'cost-atvsld',
      pageTitle: 'Chi phí ATVSLĐ',
      breadcrumb: [
        {
          text: 'Chi phí ATVSLĐ',
        },
      ],
    },
  },

  // Quản lý bồi dưỡng bằng hiện vật
  {
    path: '/an-toan-ve-sinh-lao-dong/boi-duong-hien-vat/danh-sach',
    name: 'foster',
    component: () => import('@/views/occupational-safety-health/foster/pages/List.vue'),
    meta: {
      parent: 'foster',
      pageTitle: 'Quản lý bồi dưỡng bằng hiện vật',
      breadcrumb: [
        {
          text: 'Danh sách bồi dưỡng bằng hiện vật',
        },
      ],
    },
  },

  // Thêm file Quản lý bồi dưỡng bằng hiện vật
  {
    path: '/an-toan-ve-sinh-lao-dong/boi-duong-hien-vat/them-tu-tep',
    name: 'import-foster',
    component: () => import('@/views/occupational-safety-health/foster/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'foster',
      pageTitle: 'Quản lý bồi dưỡng bằng hiện vật',
      breadcrumb: [
        {
          text: 'Danh sách bồi dưỡng bằng hiện vật',
          to: '/an-toan-ve-sinh-lao-dong/boi-duong-hien-vat/danh-sach',
        },
        {
          text: 'Thêm file bồi dưỡng bằng hiện vật',
          active: true,
        },
      ],
    },
  },

  // Đề nghị cấp phép kiểm định ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/de-nghi-cap-giay-huan-luyen',
    name: 'licensing',
    component: () => import('@/views/occupational-safety-health/licensing/pages/List.vue'),
    meta: {
      parent: 'licensing',
      pageTitle: 'Đề nghị cấp phép kiểm định VSATLĐ',
      breadcrumb: [
        {
          text: 'Danh sách đề nghị cấp phép kiểm định VSANLĐ',
        },
      ],
    },
  },

  // Kiểm định an toàn lao động
  {
    path: '/an-toan-ve-sinh-lao-dong/kiem-dinh-an-toan-lao-dong/danh-sach',
    name: 'inspection-safety',
    component: () => import('@/views/occupational-safety-health/inspection-safety/pages/List.vue'),
    meta: {
      parent: 'inspection-safety',
      pageTitle: 'Cấp giấy chứng nhận kiểm định',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Cấp giấy chứng nhận kiểm định',
          active: true,
        },
      ],
    },
  },

  // Phân loại tai nạn lao động
  // Loại chấn thương
  {
    path: '/an-toan-ve-sinh-lao-dong/loai-chan-thuong/danh-sach',
    name: 'classification-list',
    component: () => import('@/views/occupational-safety-health/classification/pages/List.vue'),
    meta: {
      parent: 'classification-list',
      pageTitle: 'Loại chấn thương',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Loại chấn thương',
          active: true,
        },
      ],
    },
  },
  // Thêm file loại chấn thương
  {
    path: '/an-toan-ve-sinh-lao-dong/loai-chan-thuong/them-tu-tep',
    name: 'import-injury-management',
    component: () => import('@/views/occupational-safety-health/classification/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'classification-list',
      pageTitle: 'Loại chấn thương',
      breadcrumb: [
        {
          text: 'Loại chấn thương',
          to: '/an-toan-ve-sinh-lao-dong/loai-chan-thuong/danh-sach',
        },
        {
          text: 'Thêm file loại chấn thương',
          active: true,
        },
      ],
    },
  },

  // Đề nghị cấp phép huấn luyện ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/huan-luyen-ATVSLD',
    name: 'training-atvsld',
    component: () => import('@/views/occupational-safety-health/training/pages/List.vue'),
    meta: {
      parent: 'training-atvsld',
      pageTitle: 'Đề nghị huấn luyện kiểm định ATVSLĐ',
      breadcrumb: [
        {
          text: 'Đề nghị huấn luyện kiểm định ATVSLĐ ',
        },
      ],
    },
  },

  // Cấp giấy chứng nhân huấn luyện
  {
    path: '/an-toan-ve-sinh-lao-dong/cap-giay-chung-nhan-huan-luyen/danh-sach',
    name: 'inspection-training',
    component: () => import('@/views/occupational-safety-health/inspection-training/pages/List.vue'),
    meta: {
      parent: 'inspection-training',
      pageTitle: 'Cấp giấy chứng nhân huấn luyến',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Cấp giấy chứng nhân huấn luyến',
          active: true,
        },
      ],
    },
  },

  // Báo cáo
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-an-toan-ve-sinh-lao-dong/danh-sach',
    name: 'inspection-report',
    component: () => import('@/views/occupational-safety-health/inspection-report/pages/List.vue'),
    meta: {
      parent: 'inspection-report',
      pageTitle: 'Báo cáo an toàn vệ sinh lao động',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-don-vi-cap-huyen/danh-sach',
    name: 'report-district',
    component: () => import('@/views/occupational-safety-health/report-district/pages/List.vue'),
    meta: {
      parent: 'report-district',
      pageTitle: 'Báo cáo đơn vị cấp huyện',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo đơn vị cấp huyện',
          active: true,
        },
      ],
    },
  },

  // Khai báo tai nạn lao động
  {
    path: '/an-toan-ve-sinh-lao-dong/khai-bao-tai-nan-lao-dong/danh-sach',
    name: 'declare-accident',
    component: () => import('@/views/occupational-safety-health/declare-accident/pages/List.vue'),
    meta: {
      parent: 'declare-accident',
      pageTitle: 'Khai báo tai nạn lao động',
      breadcrumb: [
        {
          text: 'Khai báo tai nạn lao động',
        },
      ],
    },
  },

  // Khai báo tai nạn lao động
  {
    path: '/an-toan-ve-sinh-lao-dong/khai-bao-tai-nan-lao-dong/tao-moi',
    name: 'declare-accident-create',
    component: () => import('@/views/occupational-safety-health/declare-accident/pages/Create.vue'),
    meta: {
      parent: 'declare-accident',
      pageTitle: 'Khai báo tai nạn lao động',
      breadcrumb: [
        {
          text: 'Khai báo tai nạn lao động',
          to: '/an-toan-ve-sinh-lao-dong/khai-bao-tai-nan-lao-dong/danh-sach',
        },
        {
          text: 'Thêm tai nạn lao động',
          active: true,
        },
      ],
    },
  },

  // Khai báo tai nạn lao động
  {
    path: '/an-toan-ve-sinh-lao-dong/khai-bao-tai-nan-lao-dong/chinh-sua/:id',
    name: 'declare-accident-edit',
    component: () => import('@/views/occupational-safety-health/declare-accident/pages/Create.vue'),
    meta: {
      parent: 'declare-accident',
      pageTitle: 'Khai báo tai nạn lao động',
      breadcrumb: [
        {
          text: 'Khai báo tai nạn lao động',
          to: '/an-toan-ve-sinh-lao-dong/khai-bao-tai-nan-lao-dong/danh-sach',
        },
        {
          text: 'Chỉnh sửa tai nạn lao động',
          active: true,
        },
      ],
    },
  },

  // Đánh giá rủi ro
  {
    path: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/danh-sach',
    name: 'risk-evaluation',
    component: () => import('@/views/occupational-safety-health/risk-evaluation/pages/List.vue'),
    meta: {
      parent: 'risk-evaluation',
      pageTitle: 'Đánh giá rủi ro',
      breadcrumb: [
        {
          text: 'Đánh giá rủi ro',
          to: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/danh-sach',
        },
      ],
    },
  },

  // Tạo Đánh giá rủi ro
  {
    path: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/tao-moi',
    name: 'risk-evaluation-create',
    component: () => import('@/views/occupational-safety-health/risk-evaluation/pages/Create.vue'),
    meta: {
      parent: 'risk-evaluation',
      pageTitle: 'Đánh giá rủi ro',
      breadcrumb: [
        {
          text: 'Đánh giá rủi ro',
          to: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/danh-sach',
        },
        {
          text: 'Thêm đánh giá rủi ro',
          active: true,
        },
      ],
    },
  },

  // CHỉnh sửa Đánh giá rủi ro
  {
    path: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/chinh-sua/:id',
    name: 'risk-evaluation-edit',
    component: () => import('@/views/occupational-safety-health/risk-evaluation/pages/Create.vue'),
    meta: {
      parent: 'risk-evaluation',
      pageTitle: 'Đánh giá rủi ro',
      breadcrumb: [
        {
          text: 'Đánh giá rủi ro',
          to: '/an-toan-ve-sinh-lao-dong/danh-gia-rui-ro/danh-sach',
        },
        {
          text: 'Chỉnh sửa đánh giá rủi ro',
          active: true,
        },
      ],
    },
  },

  // Thực hiện huấn luyện ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/danh-sach',
    name: 'perform-training',
    component: () => import('@/views/occupational-safety-health/perform-training/pages/List.vue'),
    meta: {
      parent: 'perform-training',
      pageTitle: 'Thực hiện huấn luyện ATVSLĐ',
      breadcrumb: [
        {
          text: 'Thực hiện huấn luyện ATVSLĐ',
        },
      ],
    },
  },

  // Tạo Thực hiện huấn luyện ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/tao-moi',
    name: 'perform-training-create',
    component: () => import('@/views/occupational-safety-health/perform-training/pages/Create.vue'),
    meta: {
      parent: 'perform-training',
      pageTitle: 'Thực hiện huấn luyện ATVSLĐ',
      breadcrumb: [
        {
          text: 'Thực hiện huấn luyện ATVSLĐ',
          to: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/danh-sach',
        },
        {
          text: 'Thêm huấn luyện',
          active: true,
        },
      ],
    },
  },

  // CHỉnh sửa Thực hiện huấn luyện ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/chinh-sua/:id',
    name: 'perform-training-edit',
    component: () => import('@/views/occupational-safety-health/perform-training/pages/Create.vue'),
    meta: {
      parent: 'perform-training',
      pageTitle: 'Thực hiện huấn luyện ATVSLĐ',
      breadcrumb: [
        {
          text: 'Thực hiện huấn luyện ATVSLĐ',
          to: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/danh-sach',
        },
        {
          text: 'Chỉnh sửa huấn luyện',
          active: true,
        },
      ],
    },
  },

  // Thêm từ file Thực hiện huấn luyện ATVSLĐ
  {
    path: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/them-tu-file/:id',
    name: 'import-perform-training',
    component: () => import('@/views/occupational-safety-health/perform-training/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'perform-training',
      pageTitle: 'Thực hiện huấn luyện ATVSLĐ',
      breadcrumb: [
        {
          text: 'Thực hiện huấn luyện ATVSLĐ',
          to: '/an-toan-ve-sinh-lao-dong/thuc-hien-huan-luyen/danh-sach',
        },
        {
          text: 'Thêm từ file huấn luyện',
          active: true,
        },
      ],
    },
  },

  // Tự huấn luyện
  {
    path: '/an-toan-ve-sinh-lao-dong/tu-huan-luyen/danh-sach',
    name: 'self-training',
    component: () => import('@/views/occupational-safety-health/self-training/pages/List.vue'),
    meta: {
      parent: 'self-training',
      pageTitle: 'Tự huấn luyện',
      breadcrumb: [
        {
          text: 'Tự huấn luyện',
        },
      ],
    },
  },

  // Tạo Tự huấn luyện
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-tai-nan-lao-dong/tao-moi',
    name: 'self-training-create',
    component: () => import('@/views/occupational-safety-health/self-training/pages/Create.vue'),
    meta: {
      parent: 'self-training',
      pageTitle: 'Tự huấn luyện',
      breadcrumb: [
        {
          text: 'Tự huấn luyện',
        },
        {
          text: 'Thêm huấn luyện',
          active: true,
        },
      ],
    },
  },

  // CHỉnh sửa Tự huấn luyện
  {
    path: '/an-toan-ve-sinh-lao-dong/tu-huan-luyen/chinh-sua/:id',
    name: 'self-training-edit',
    component: () => import('@/views/occupational-safety-health/self-training/pages/Create.vue'),
    meta: {
      parent: 'self-training',
      pageTitle: 'Tự huấn luyện',
      breadcrumb: [
        {
          text: 'Tự huấn luyện',
          to: '/an-toan-ve-sinh-lao-dong/tu-huan-luyen/danh-sach',
        },
        {
          text: 'Chỉnh sửa huấn luyện',
          active: true,
        },
      ],
    },
  },

  // Quản lý tai nạn lao động
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-tai-nan-lao-dong/danh-sach',
    name: 'accidentManagement',
    component: () => import('@/views/occupational-safety-health/accident-management/pages/List.vue'),
    meta: {
      parent: 'accidentManagement',
      pageTitle: 'Quản lý tai nạn lao động',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quản lý tai nạn lao động',
          active: true,
        },
      ],
    },
  },

  // Quan trắc môi trường lao động doanh nghiệp
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/occupational-safety-health/environmental-monitoring/pages/List.vue'),
    name: 'environmental-monitoring',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/tao-moi',
    component: () => import('@/views/occupational-safety-health/environmental-monitoring/pages/EditMonitor.vue'),
    name: 'environmental-monitoring-add',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
          to: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
        },
        {
          text: 'Thêm quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/chinh-sua/:id',
    component: () => import('@/views/occupational-safety-health/environmental-monitoring/pages/EditMonitor.vue'),
    name: 'environmental-monitoring-edit',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
          to: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
        },
        {
          text: 'Sửa quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/them-tu-tep',
    name: 'import-environmental-monitoring',
    component: () => import('@/views/occupational-safety-health/environmental-monitoring/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'environmental-monitoring',
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
        },
        {
          text: 'Thêm file quan trắc môi trường',
          active: true,
        },
      ],
    },
  },

  // Quan trắc môi trường SỞ
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/occupational-safety-health/environmental-monitoring-department/pages/List.vue'),
    name: 'environmental-monitoring-department',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/tao-moi',
    component: () => import('@/views/occupational-safety-health/environmental-monitoring-department/pages/EditMonitor.vue'),
    name: 'environmental-monitoring-department-add',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
          to: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
        },
        {
          text: 'Thêm quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/chinh-sua/:id',
    component: () => import('@/views/occupational-safety-health/environmental-monitoring-department/pages/EditMonitor.vue'),
    name: 'environmental-monitoring-department-edit',
    meta: {
      pageTitle: 'Quan trắc môi trường',
      breadcrumb: [
        {
          text: 'Quan trắc môi trường',
          to: '/an-toan-ve-sinh-lao-dong/quang-trac-moi-truong/danh-sach',
        },
        {
          text: 'Sửa quan trắc môi trường',
          active: true,
        },
      ],
    },
  },
  // Quản lí khai báo thiết bị sở
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-khai-bao-thiet-bi/danh-sach',
    component: () => import('@/views/occupational-safety-health/declare-equipment-department/List.vue'),
    name: 'declare-equipment-department',
    meta: {
      pageTitle: 'Quản lý khai báo thiết bị',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quản lý khai báo thiết bị',
          active: true,
        },
      ],
    },
  },
  // Thông báo tổ chức huấn luyện N2 và N3 của các trung tâm hoạt động huấn luyện hạng C Doanh nghiệp
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-bao-to-chuc-huan-luyen/danh-sach',
    component: () => import('@/views/occupational-safety-health/notification-training/pages/List.vue'),
    name: 'notification-training',
    meta: {
      pageTitle: 'Thông báo tổ chức huấn luyện',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Thông báo tổ chức huấn luyện N2 và N3 của các trung tâm hoạt động huấn luyện hạng C',
          active: true,
        },
      ],
    },
  },
  // Thông báo tổ chức huấn luyện N2 và N3 của các trung tâm hoạt động huấn luyện hạng C Sở
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-bao-to-chuc-huan-luyen-so/danh-sach',
    component: () => import('@/views/occupational-safety-health/notification-training-department/pages/List.vue'),
    name: 'notification-training-department',
    meta: {
      pageTitle: 'Thông báo tổ chức huấn luyện',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Thông báo tổ chức huấn luyện N2 và N3 của các trung tâm hoạt động huấn luyện hạng C',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/thanh-tra/tao-moi',
    component: () => import('@/views/occupational-safety-health/supervise/pages/Create.vue'),
    name: 'supervise-create',
    meta: {
      pageTitle: 'Thanh tra, kiểm tra',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Thanh tra, kiểm tra',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/thanh-tra/chinh-sua/:id',
    component: () => import('@/views/occupational-safety-health/supervise/pages/Create.vue'),
    name: 'supervise-edit',
    meta: {
      pageTitle: 'Thanh tra, kiểm tra',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Thanh tra, kiểm tra',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/thanh-tra-so/danh-sach',
    component: () => import('@/views/occupational-safety-health/supervise/List.vue'),
    name: 'supervise-department',
    meta: {
      pageTitle: 'Thanh tra, kiểm tra',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Thanh tra, kiểm tra',
          active: true,
        },
      ],
    },
  },

  // quy chuẩn quốc gia
  {
    path: '/an-toan-ve-sinh-lao-dong/quy-chuan-quoc-gia/bui',
    name: 'observation-monitor',
    component: () => import('@/views/occupational-safety-health/menu-observation-monitor/pages/MenuObservition.vue'),
    meta: {
      pageTitle: 'Quy chuẩn quốc gia',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quy chuẩn quốc gia',
          active: true,
        },
      ],
    },
  },

  {
    path: '/an-toan-ve-sinh-lao-dong/quy-chuan-quoc-gia/:type',
    name: 'observation-monitor-type',
    component: () => import('@/views/occupational-safety-health/menu-observation-monitor/pages/MenuObservition.vue'),
    meta: {
      parent: 'observation-monitor',
      pageTitle: 'Quy chuẩn quốc gia',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quy chuẩn quốc gia',
          active: true,
        },
      ],
    },
  },

  // yếu tố chấn thương
  {
    path: '/an-toan-ve-sinh-lao-dong/yeu-to-chan-thuong/danh-sach',
    name: 'injury-element',
    component: () => import('@/views/occupational-safety-health/injury-element/page/InjuryElement.vue'),
    meta: {
      parent: 'injury-element',
      pageTitle: 'Yếu tố chấn thương',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Yếu tố chấn thương',
          active: true,
        },
      ],
    },
  },
  // thêm từ file yếu tố chấn thương
  {
    path: '/an-toan-ve-sinh-lao-dong/yeu-to-chan-thuong/them-tu-tep',
    name: 'import-injury-element',
    component: () => import('@/views/occupational-safety-health/injury-element/page/components/ListImportFile.vue'),
    meta: {
      parent: 'injury-element',
      pageTitle: 'Yếu tố chấn thương',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Yếu tố chấn thương',
          active: true,
        },
      ],
    },
  },

  // Sổ thống kê tai nạn lao động của cơ quan chuyên ngành
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/danh-sach',
    name: 'accedent-statistical',
    component: () => import('@/views/occupational-safety-health/accident-statistical/pages/AccidentStatistical.vue'),
    meta: {
      parent: 'accedent-statistical',
      pageTitle: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
          active: true,
        },
      ],
    },
  },

  // thêm thống kê tai nạn lao động của cơ quan chuyên ngành
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/them',
    name: 'accedent-statistical-add',
    component: () => import('@/views/occupational-safety-health/accident-statistical/pages/components/CreateAccidentStatistical.vue'),
    meta: {
      parent: 'accedent-statistical',
      pageTitle: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/danh-sach',
        },
      ],
    },
  },
  // chỉnh sửa thống kê tai nạn lao động của cơ quan chuyên ngành
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/chinh-sua/:id',
    name: 'accedent-statistical-edit',
    component: () => import('@/views/occupational-safety-health/accident-statistical/pages/components/CreateAccidentStatistical.vue'),
    meta: {
      parent: 'accedent-statistical',
      pageTitle: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/danh-sach',
        },
      ],
    },
  },
  // chỉnh sửa thống kê tai nạn lao động của cơ quan chuyên ngành
  {
    path: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/them-tu-tep',
    name: 'accedent-statistical-import',
    component: () => import('@/views/occupational-safety-health/accident-statistical/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'accedent-statistical',
      pageTitle: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Sổ thống kê tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/thong-ke-tai-nan-lao-dong-co-quan/danh-sach',
        },
      ],
    },
  },
  // Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach',
    name: 'accedent-specialize',
    component: () => import('@/views/occupational-safety-health/accident-specialize-report/page/AccidentSpecializeReport.vue'),
    meta: {
      parent: 'accedent-specialize',
      pageTitle: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
          active: true,
        },
      ],
    },
  },

  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/tao-moi',
    name: 'accedent-specialize-add',
    component: () => import('@/views/occupational-safety-health/accident-specialize-report/page/components/EditElement.vue'),
    meta: {
      parent: 'accedent-specialize',
      pageTitle: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach',
        },
        {
          text: 'Thêm mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/chinh-sua/:id',
    name: 'accedent-specialize-edit',
    component: () => import('@/views/occupational-safety-health/accident-specialize-report/page/components/EditElement.vue'),
    meta: {
      parent: 'accedent-specialize',
      pageTitle: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach',
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/them-tu-tep/:id',
    name: 'accedent-specialize-type-import',
    component: () => import('@/views/occupational-safety-health/accident-specialize-report/page/components/ListImportFileType.vue'),
    meta: {
      parent: 'accedent-specialize',
      pageTitle: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach',
        },
        {
          text: 'Thêm mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/them-tu-tep/:id/:type',
    name: 'accedent-specialize-title-import',
    component: () => import('@/views/occupational-safety-health/accident-specialize-report/page/components/ListImportFileTitle.vue'),
    meta: {
      parent: 'accedent-specialize',
      pageTitle: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Báo cáo tổng hợp tai nạn lao động của cơ quan chuyên ngành',
          to: '/an-toan-ve-sinh-lao-dong/bao-cao-tong-hop-tai-nan-lao-dong-chuyen-nganh/danh-sach',
        },
        {
          text: 'Thêm mới',
          active: true,
        },
      ],
    },
  },
]
