export default [
  {
    path: '/nguoi-lao-dong/giay-phep-lao-dong/danh-sach',
    name: 'work-permit',
    component: () => import('@/views/management-workers/worker-permit/pages/List.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Đề nghị cấp giấy phép người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Đề nghị cấp giấy phép người lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/giay-phep-lao-dong/tao-moi',
    name: 'work-permit-add-license',
    component: () => import('@/views/management-workers/worker-permit/pages/AddLicense.vue'),
    meta: {
      parent: 'work-permit',
      pageTitle: 'Thêm giấy cấp phép',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Đề nghị cấp giấy phép lao động',
          to: '/nguoi-lao-dong/giay-phep-lao-dong/danh-sach',
        },
        {
          text: 'Thêm giấy cấp phép',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/giay-phep-lao-dong/chinh-sua/:id',
    name: 'work-permit-edit-license',
    component: () => import('@/views/management-workers/worker-permit/pages/AddLicense.vue'),
    meta: {
      parent: 'work-permit',
      pageTitle: 'Sửa giấy cấp phép',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Đề nghị cấp giấy phép lao động',
          to: '/nguoi-lao-dong/giay-phep-lao-dong/danh-sach',
        },
        {
          text: 'Sửa giấy cấp phép',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/giay-phep-lao-dong/sua-giay-xac-nhan',
    name: 'work-permit-edit-certificate',
    component: () => import('@/views/management-workers/worker-permit/pages/AddCertificate.vue'),
    meta: {
      parent: 'work-permit',
      pageTitle: 'Sửa giấy xác nhận',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Đề nghị cấp giấy phép lao động',
          to: '/nguoi-lao-dong/giay-phep-lao-dong/danh-sach',
        },
        {
          text: 'Sửa giấy xác nhận',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/giay-phep-lao-dong/tao-moi-giay-xac-nhan',
    name: 'work-permit-add-certificate',
    component: () => import('@/views/management-workers/worker-permit/pages/AddCertificate.vue'),
    meta: {
      parent: 'work-permit',
      pageTitle: 'Thêm giấy xác nhận',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Đề nghị cấp giấy phép lao động',
          to: '/nguoi-lao-dong/giay-phep-lao-dong/danh-sach',
        },
        {
          text: 'Thêm giấy xác nhận',
          active: true,
        },
      ],
    },
  },
]
