/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import axios from '@axios'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataValidUser: [],
  dataNoValidUser: [],
  dataTooltip: [],
})

const getters = {
  dataValidUser: state => state.dataValidUser,
  dataNoValidUser: state => state.dataNoValidUser,
  dataTooltip: state => state.dataTooltip,
}

const mutations = {
  SET_DATA_STRUCT_ID(state, data) {
    state.dataOrganizationStructId = data
  },
  DATA_VALID_USER(state, data) {
    state.dataValidUser = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.dataNoValidUser = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
}

const actions = {

  // Download tập tin nội dung cơ cấu tổ chức về máy
  async downloadFileOrganizationStruct() {
    try {
      axiosApiInstance({
        url: '/OrganizationStructure/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'CoCauToChuc.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Download tập tin mẫu cơ cấu tổ chức về máy
  async downloadExampleFileStruct() {
    try {
      axiosApiInstance({
        url: '/OrganizationStructure/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'CoCauToChuc.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Thêm cơ cấu tổ chức từ file excel
  async getApiExcelStruct({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_FILE_ORGANIZATION, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
