export default {
  // Danh mục thiết bị kiểm định
  LIST_INSPECTOR: 'Inspector/inspectors',
  CREATE_INSPECTOR: 'Inspector/inspectors',
  EDIT_INSPECTOR: 'Inspector/inspectors',
  IMPORT_FILE_INSPECTOR: 'Inspector/import-inspector-from-json',
  DETAIL_INSPECTOR: 'Inspector/inspectors/',
  DELETE_INSPECTOR: 'Inspector/delete-inspectors',
  ADD_FILE_TO_INSPECTOR: 'Inspector/download-file-inspector',
}
