import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import user from './routes/users/user'
import positionJob from './routes/users/positionJob'
import history from './routes/users/history'
import UserGroup from './routes/users/UserGroup'
import TrashBin from './routes/users/TrashBin'
import userType from './routes/users/userType'
import typeOfBusiness from './routes/businesses/typeOfBusiness'
import struct from './routes/users/struct'
import business from './routes/businesses/business'
import businessSector from './routes/businesses/businessSector'
import healtClassification from './routes/workers/healtClassification'
import occupation from './routes/workers/occupation'
import worker from './routes/workers/worker'
import workerLicense from './routes/workers/workerLicense'
import news from './routes/news'
import occupationalSafetyHealth from './routes/occupational-safety-health'
import occupationalSafetyClassification from './routes/safe/occupationalSafetyClassification'
import workPermit from './routes/workers/workPermit'
import connective from './routes/connective'
import workerAge from './routes/statistical/statistical'
import setting from './routes/setting/setting'
import submitReport from './routes/submitReport'
import inspectEquip from './routes/businesses/inspectEquip'
import declareEquip from './routes/businesses/declareEquip'
import manageDoc from './routes/businesses/manageDoc'
import archive from './routes/archive'
import category from './routes/categoryFile'
import classify from './routes/businesses/classifyFile'
import statusReport from './routes/businesses/statusReport'
import digitally from './routes/businesses/digitally-signed'
import service from './routes/service'
import message from './routes/message/message'
import homePage from './routes/home-page'
import workerReport from './routes/workers/workerReport'
import occupationalDisease from './routes/workers/occupationalDisease'
import workerDisease from './routes/workers/workerDisease'
import groupTraining from './routes/workers/groupTraining'
import employment from './routes/employment'
import workerUmployed from './routes/workers/wokerUmployed'
import violation from './routes/businesses/violation'
import affiliatedUnits from './routes/management-affilliated-units/affiliatedUnits'
import miningAccount from './routes/management-affilliated-units/miningAccount'
import useDevice from './routes/businesses/useDevice'
import conditionTraining from './routes/conditionTraining'
import conditionDepartment from './routes/conditionDepartment'
import inspectorDepartment from './routes/inspector-department'
import userTracing from './routes/users/userTracing'
import collectInforWorker from './routes/collectInforWorker'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import educationDepartment from './routes/users/educationDepartment'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...user,
    ...dashboard,
    ...pages,
    ...UserGroup,
    ...positionJob,
    ...history,
    ...TrashBin,
    ...userType,
    ...typeOfBusiness,
    ...struct,
    ...business,
    ...businessSector,
    ...news,
    ...occupation,
    ...occupationalSafetyHealth,
    ...worker,
    ...workerLicense,
    ...occupationalSafetyClassification,
    ...workPermit,
    ...connective,
    ...workerAge,
    ...setting,
    ...submitReport,
    ...healtClassification,
    ...inspectEquip,
    ...declareEquip,
    ...manageDoc,
    ...archive,
    ...category,
    ...classify,
    ...statusReport,
    ...digitally,
    ...service,
    ...message,
    ...homePage,
    ...workerReport,
    ...occupationalDisease,
    ...workerDisease,
    ...groupTraining,
    ...employment,
    ...violation,
    ...workerUmployed,
    ...affiliatedUnits,
    ...miningAccount,
    ...useDevice,
    ...conditionTraining,
    ...conditionDepartment,
    ...inspectorDepartment,
    ...userTracing,
    ...educationDepartment,
    ...collectInforWorker,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const setFaviconAndPageTitle = (icon, title) => {
  const link = document.querySelector("link[rel~='icon']")
  if (link) {
    link.href = `${process.env.VUE_APP_BASE_API}${icon}`
  }
  // document.title = title
}

const getPortalInfo = async () => {
  const app = document.getElementById('app')
  try {
    const { data } = await axiosApiInstance.get('Home/configuration')
    if (data) {
      if (data?.favicon) {
        app.setAttribute('data-icon', data?.favicon)
      } else {
        app.setAttribute('data-icon', '')
      }
      // if (data?.title) {
      //   app.setAttribute('data-title', data.title)
      // } else {
      //   app.setAttribute('data-title', 'Cls 4.0')
      // }
    } else {
      app.setAttribute('data-icon', '')
      // app.setAttribute('data-title', 'Cls 4.0')
    }
    setFaviconAndPageTitle(app.dataset.icon)
  } catch {
    app.setAttribute('data-icon', '')
    // app.setAttribute('data-title', 'Cls 4.0')
    setFaviconAndPageTitle(app.dataset.icon)
  }
}

router.beforeEach((to, _, next) => {
  const app = document.getElementById('app')
  if (app.dataset.icon) {
    Vue.nextTick(() => {
      setFaviconAndPageTitle(app.dataset.icon)
    })
  } else {
    getPortalInfo()
  }

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
