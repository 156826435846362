export default [
  {
    path: '/nguoi-dung/lich-su-truy-cap/danh-sach',
    name: 'history',
    component: () => import('@/views/management-user/history/pages/List.vue'),
    meta: {
      parent: 'list-user',
      pageTitle: 'Lịch sử truy cập',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Thông tin',
          active: true,
        },
      ],
    },
  },
]
