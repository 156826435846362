export default [
  {
    path: '/an-toan-ve-sinh-lao-dong/quan-li-kiem-dinh-vien-so/danh-sach',
    name: 'inspector-department',
    component: () => import('@/views/occupational-safety-health/inspector-department/pages/List.vue'),
    meta: {
      parent: 'inspector-department',
      pageTitle: 'Quản lý kiểm định viên',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Quản lý kiểm định viên',
          active: true,
        },
      ],
    },
  },
]
