export default [
  {
    path: '/nguoi-dung/kieu-nguoi-dung/danh-sach',
    name: 'list-user-type',
    component: () => import('@/views/management-user/user-type/pages/List.vue'),
    meta: {
      parent: 'list-user',
      pageTitle: 'Danh sách kiểu người dùng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Danh sách kiểu người dùng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/kieu-nguoi-dung/tao-moi',
    name: 'create-user-type',
    component: () => import('@/views/management-user/user-type/pages/Create.vue'),
    meta: {
      parent: 'list-user-type',
      pageTitle: 'Danh sách kiểu người dùng',
      breadcrumb: [
        {
          text: 'Danh sách kiểu người dùng',
          to: '/nguoi-dung/kieu-nguoi-dung/danh-sach',
        },
        {
          text: 'Thêm kiểu người dùng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/kieu-nguoi-dung/chinh-sua/:id',
    name: 'edit-user-type',
    meta: {
      parent: 'list-user-type',
      pageTitle: 'Danh sách kiểu người dùng',
      breadcrumb: [
        {
          text: 'Danh sách kiểu người dùng',
          to: '/nguoi-dung/kieu-nguoi-dung/danh-sach',
        },
        {
          text: 'Sửa kiểu người dùng',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/user-type/pages/Create.vue'),
  },
]
