export default [
  {
    path: '/loai-hinh-doanh-nghiep/danh-sach',
    component: () => import('@/views/management-business/type-of-business/pages/List.vue'),
    name: 'type-of-business',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Loại hình doanh nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/loai-hinh-doanh-nghiep/them-tu-file',
    component: () => import('@/views/management-business/type-of-business/pages/components/ListImportFile.vue'),
    name: 'type-of-business-list',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Loại hình doanh nghiệp',
          to: '/loai-hinh-doanh-nghiep/danh-sach',
        },
        {
          text: 'Danh sách thêm Loại hình doanh nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cap-nhat-loai-hinh-doanh-nghiep/:id',
    component: () => import('@/views/management-business/type-of-business/pages/List.vue'),
    name: 'type-of-business-edit',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/type-of-business',
        },
        {
          text: 'Loại hình doanh nghiệp',
          active: true,
        },
      ],
    },
  },
]
