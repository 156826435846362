export default [
  {
    path: '/an-toan-ve-sinh-lao-dong/tu-cong-bo-du-dieu-kien-huan-luyen',
    name: 'condition-training',
    component: () => import('@/views/occupational-safety-health/condition-training/pages/List.vue'),
    meta: {
      parent: 'condition-training',
      pageTitle: 'Tự công bố đủ điều kiện huấn luyện hạng A ',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Tự công bố đủ điều kiện huấn luyện hạng A ',
          active: true,
        },
      ],
    },
  },
]
