export default [
  {
    path: '/quan-ly-tai-lieu-so/danh-sach',
    name: 'manage-doc',
    component: () => import('@/views/management-business/digitally-signed/pages/List.vue'),
    meta: {
      parent: 'list-businessSector',
      pageTitle: 'Quản lý tài liệu ký số',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Quản lý tài liệu ký số',
          active: true,
        },
      ],
    },
  },
]
