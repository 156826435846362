import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = {
  dataValidUserGroup: '',
  dataNoValidUserGroup: '',
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  DATA_VALID_USER_GROUP(state, data) {
    state.dataValidUserGroup = data
  },
  // eslint-disable-next-line no-shadow
  DATA_NO_VALID_USER_GROUP(state, data) {
    state.dataNoValidUserGroup = data
  },
}

const actions = {

  // =============================== download file mẫu về máy ===============================================
  async downloadSampleUserGroup() {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_SAMPLE_USER_GROUP, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'FileMauNhomNguoiDung.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // =============================== download file all excel về máy ===============================================
  async downloadExcelAll() {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_EXCEL_ALL, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NhomNguoiDung.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
  // eslint-disable-next-line consistent-return
  async getApiExcelUserGroup({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.GET_API_EXCEL_USER_GROUP, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER_GROUP', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER_GROUP', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        messageErr: e,
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
