/* eslint-disable no-shadow */
/* eslint-disable consistent-return */

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  validWorkType: [],
  validNoWorkType: [],
  dataTooltipType: [],
  validWorkTitle: [],
  validNoWorkTitle: [],
  dataTooltipTitle: [],
})
const getters = {
  validWorkType: state => state.validWorkType,
  validNoWorkType: state => state.validNoWorkType,
  dataTooltipType: state => state.dataTooltipType,
  validWorkTitle: state => state.validWorkTitle,
  validNoWorkTitle: state => state.validNoWorkTitle,
  dataTooltipTitle: state => state.dataTooltipTitle,
}

const actions = {
  async downloadExampleFileType() {
    try {
      axiosApiInstance({
        url: ConstantsApi.TEMPLATE_TYPE, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'PhanLoaiTheoLoaiHinhCoSo.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      //
    } catch (e) {
      //
    }
  },
  async getApiExcelType({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_TYPE, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_TYPE', apiExcel?.data?.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_TYPE', apiExcel?.data?.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },
  async downloadExampleFileTitle() {
    try {
      axiosApiInstance({
        url: ConstantsApi.TEMP_TITLE, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'FileMau.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      //
    } catch (e) {
      //
    }
  },
  async getApiExcelTitle({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_TITLE, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_TITLE', apiExcel?.data?.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_TITLE', apiExcel?.data?.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },

}
const mutations = {
  DATA_VALID_TYPE(state, data) {
    state.validWorkType = data
  },
  DATA_NO_VALID_TYPE(state, data) {
    state.validNoWorkType = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltipType = apiExcel.data.data
  },
  DATA_VALID_TITLE(state, data) {
    state.validWorkTitle = data
  },
  DATA_NO_VALID_TITLE(state, data) {
    state.validNoWorkTitle = data
  },
  DATA_TOOLTIP_TITLE(state, apiExcel) {
    state.dataTooltipTitle = apiExcel.data.data
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
