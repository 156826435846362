export default [
  {
    path: '/nguoi-lao-dong/danh-muc-nghe-nghiep/danh-sach',
    name: 'list-occupation',
    component: () => import('@/views/management-workers/occupation-list/pages/List.vue'),
    meta: {
      parent: 'list-occupation',
      pageTitle: 'Nghề nghiệp',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Danh mục nghề nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/danh-muc-nghe-nghiep/them-tu-tep',
    name: 'import-occupation',
    component: () => import('@/views/management-workers/occupation-list/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'list-occupation',
      pageTitle: 'Nghề nghiệp',
      breadcrumb: [
        {
          text: 'Danh mục nghề nghiệp',
          to: '/nguoi-lao-dong/danh-muc-nghe-nghiep/danh-sach',
        },
        {
          text: 'Thêm file danh mục nghề nghiệp',
          active: true,
        },
      ],
    },
  },
]
