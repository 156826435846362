export default [
  {
    path: '/nguoi-dung/vi-tri-cong-viec/danh-sach',
    component: () => import('@/views/management-user/position-job/pages/List.vue'),
    name: 'position-job',
    meta: {
      pageTitle: 'Vị trí công việc',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/position-job',
        },
        {
          text: 'Vị trí công việc',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/vi-tri-cong-viec/chinh-sua/:id',
    component: () => import('@/views/management-user/position-job/pages/List.vue'),
    name: 'position-job-edit',
    meta: {
      pageTitle: 'Vị trí công việc',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/nguoi-dung/vi-tri-cong-viec/danh-sach',
        },
        {
          text: 'Vị trí công việc',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/vi-tri-cong-viec/them-tu-tep',
    component: () => import('@/views/management-user/position-job/pages/components/ListImportFile.vue'),
    name: 'import-position-job-file',
    meta: {
      pageTitle: 'Vị trí công việc',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
        },
        {
          text: 'Vị trí công việc',
          to: '/nguoi-dung/vi-tri-cong-viec/danh-sach',
        },
        {
          text: 'Danh sách vị trí công việc',
          active: true,
        },
      ],
    },
  },
]
