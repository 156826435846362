export default [
  {
    path: '/thung-rac',
    component: () => import('@/views/management-user/Trash-Bin/pages/List.vue'),
    name: 'Trash-Bin',
    meta: {
      pageTitle: 'Thùng rác',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
        },
        {
          text: 'Thùng rác',
          active: true,
        },
      ],
    },
  },
]
