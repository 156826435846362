/* eslint-disable no-shadow */
/* eslint-disable consistent-return */

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  validWork: [],
  validNoWork: [],
  dataTooltip: [],
})
const getters = {
  validWork: state => state.validWork,
  validNoWork: state => state.validNoWork,
  dataTooltip: state => state.dataTooltip,
}

const actions = {
  async downloadExportFile() {
    try {
      axiosApiInstance({
        url: '/InjuryReason/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiChanThuong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  async downloadExampleFile() {
    try {
      axiosApiInstance({
        url: '/InjuryReason/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiChanThuong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_FILE_INJURY, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel?.data?.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel?.data?.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },

}
const mutations = {
  DATA_VALID_USER(state, data) {
    state.validWork = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.validNoWork = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
