export default [
  // Danh sách người lao động của doanh nghiệp
  {
    path: '/nguoi-lao-dong/danh-sach',
    name: 'worker',
    component: () => import('@/views/management-workers/worker/pages/List.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/tao-moi',
    name: 'worker-add',
    component: () => import('@/views/management-workers/worker/pages/Add.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/nguoi-lao-dong/danh-sach',
        },
        {
          text: 'Thêm người lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/chinh-sua/:id',
    name: 'worker-edit',
    meta: {
      pageTitle: 'Sửa người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/worker',
        },
        {
          text: 'Sửa người lao động',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-workers/worker/pages/Add.vue'),
  },
  {
    path: '/nguoi-lao-dong/them-tu-tep',
    name: 'worker-import',
    component: () => import('@/views/management-workers/worker/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/nguoi-lao-dong/danh-sach',
        },
        {
          text: 'Thêm người lao động',
          active: true,
        },
      ],
    },
  },

  // Danh sách người lao động của Sở
  {
    path: '/nguoi-lao-dong/danh-sach-so',
    name: 'manage-workers',
    component: () => import('@/views/management-workers/manage-workers/pages/List.vue'),
    meta: {
      parent: 'manage-workers',
      pageTitle: 'Người lao động',
      breadcrumb: [
        // {
        //   text: 'Người lao động',
        // },
        {
          text: 'Người lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/them-moi',
    name: 'manage-workers-add',
    component: () => import('@/views/management-workers/manage-workers/pages/Add.vue'),
    meta: {
      parent: 'manage-workers',
      pageTitle: 'Người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/manage-workers/list',
        },
        {
          text: 'Thêm người lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/chinh-sua-so/:id',
    name: 'manage-workers-edit',
    meta: {
      pageTitle: 'Sửa người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/manage-workers',
        },
        {
          text: 'Sửa người lao động',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-workers/manage-workers/pages/Add.vue'),
  },
  {
    path: '/nguoi-lao-dong/them-tu-file',
    name: 'manage-workers-import',
    component: () => import('@/views/management-workers/manage-workers/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'manage-workers',
      pageTitle: 'Người lao động',
      breadcrumb: [
        {
          text: 'Danh người lao động',
          to: '/manage-workers/list',
        },
        {
          text: 'Thêm người lao động',
          active: true,
        },
      ],
    },
  },
]
