export default {
  // Đơn vị cấp phòng
  API_EDUCATION_DEPARTMENT: 'EducationDepartment/district-unit',
  CREATE_EDUCATION_DEPARTMENT: 'EducationDepartment/district-unit',
  EDIT_EDUCATION_DEPARTMENT: 'EducationDepartment/education-department',
  DELETE_EDUCATION_DEPARTMENT: 'EducationDepartment/delete-education-departments',
  DETAIL_EDUCATION_DEPARTMENT: 'EducationDepartment/education-department/',
  IMPORT_FILE_EDUCATION_DEPARTMENT: 'EducationDepartment/import-education-department-from-json',
  CHANGE_PASSWORD: 'Account/reissue-password',

  // Combobox
  USER_TYPE: 'UserType/get-combobox-user-type',
  PROVINCES: 'OfficalProfile/provinces',
  DISTRICTS: 'OfficalProfile/districts',
  WARDS: 'OfficalProfile/wards',
}
