export default {
  FOREIGN_WORKER_LIST: 'CollectForeigner/collectForeigners',
  DOWNLOAD_FILE: 'CollectForeigner/file-collect-foreigner-report',
  CREATE_EDIT_FOREIGN_WORKER: 'CollectForeigner/collectForeigners',
  DETAIL_FOREIGN_WORKER: 'CollectForeigner/collectForeigners/',
  DELETE_FOREIGN_WORKER: 'CollectForeigner/delete-collect-foreigners',

  // COMBOBOX
  COMBOBOX_CITY: 'OfficalProfile/provinces',
  COMBOBOX_DISTRICT: 'OfficalProfile/districts/',
  COMBOBOX_WARD: 'OfficalProfile/wards/',
}
