export default [
  {
    path: '/nguoi-lao-dong/nguoi-lao-dong-benh-nghe-nghiep/danh-sach',
    name: 'workers-disease',
    component: () => import('@/views/management-workers/workers-disease/pages/List.vue'),
    meta: {
      parent: 'workers-disease',
      pageTitle: 'Người lao động bị bệnh nghề nghiệp',
      breadcrumb: [
        {
          text: 'Danh sách người lao động bị bệnh',
        },
      ],
    },
  },

  {
    path: '/nguoi-lao-dong/nguoi-lao-dong-benh-nghe-nghiep/them-tu-tep',
    name: 'import-workers-disease',
    component: () => import('@/views/management-workers/workers-disease/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'workers-disease',
      pageTitle: 'Người lao động bị bệnh nghề nghiệp',
      breadcrumb: [
        {
          text: 'Danh sách người lao động bị bệnh',
          to: '/nguoi-lao-dong/nguoi-lao-dong-benh-nghe-nghiep/danh-sach',
        },
        {
          text: 'Thêm file người lao động bị bệnh nghề nghiệp',
          active: true,
        },
      ],
    },
  },
]
