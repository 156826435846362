export default [
  {
    path: '/dich-vu-viec-lam/co-cau-to-chuc/danh-sach',
    component: () => import('@/views/employment-service/org-struct/pages/List.vue'),
    name: 'org-struct-employment',
    meta: {
      pageTitle: 'Cơ cấu tổ chức trung tâm dịch vụ việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Cơ cấu tổ chức trung tâm dịch vụ việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/co-cau-to-chuc/:id',
    component: () => import('@/views/employment-service/org-struct/pages/components/ListUser.vue'),
    name: 'org-struct-user-employment',
    meta: {
      pageTitle: 'Cơ cấu tổ chức trung tâm dịch vụ việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/co-cau-to-chuc/danh-sach',
        },
        {
          text: 'Cơ cấu tổ chức trung tâm dịch vụ việc làm',
          to: '/dich-vu-viec-lam/co-cau-to-chuc/danh-sach',
        },
        {
          text: 'Thêm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/danh-sach',
    component: () => import('@/views/employment-service/session/pages/List.vue'),
    name: 'session-employment',
    meta: {
      pageTitle: 'Phiên giao dịch việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Phiên giao dịch việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/tao-moi',
    component: () => import('@/views/employment-service/session/pages/Add.vue'),
    name: 'add-session-employment',
    meta: {
      pageTitle: 'Phiên giao dịch việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/danh-sach',
        },
        {
          text: 'Phiên giao dịch việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/chinh-sua/:id',
    component: () => import('@/views/employment-service/session/pages/Add.vue'),
    name: 'edit-session-employment',
    meta: {
      pageTitle: 'Phiên giao dịch việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/danh-sach',
        },
        {
          text: 'Phiên giao dịch việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-lam-viec/phien-giao-dich-viec-lam/tao-moi/:type',
    component: () => import('@/views/employment-service/session/pages/Add.vue'),
    name: 'add-type-session-employment',
    meta: {
      pageTitle: 'Phiên giao dịch việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/business',
        },
        {
          text: 'Phiên giao dịch việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/tu-van-viec-lam',
    component: () => import('@/views/employment-service/advise/pages/IndexTab.vue'),
    name: 'advise-employment',
    meta: {
      pageTitle: 'Hoạt động tư vấn việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Hoạt động tư vấn việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/tu-van-viec-lam/them-tu-tep/nguoi-lao-dong',
    component: () => import('@/views/employment-service/advise/pages/employee/ListImportFile.vue'),
    name: 'advise-employment-import-employee',
    meta: {
      pageTitle: 'Hoạt động tư vấn việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/tu-van-viec-lam',
        },
        {
          text: 'Hoạt động tư vấn việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/tu-van-viec-lam/them-tu-tep/nguoi-su-dung-lao-dong',
    component: () => import('@/views/employment-service/advise/pages/employer/ListImportFile.vue'),
    name: 'advise-employment-import-employer',
    meta: {
      pageTitle: 'Hoạt động tư vấn việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/tu-van-viec-lam',
        },
        {
          text: 'Hoạt động tư vấn việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/tu-van-viec-lam/them-tu-tep',
    component: () => import('@/views/employment-service/advise/pages/employee/ListImportFile.vue'),
    name: 'advise-employment-import-employee',
    meta: {
      pageTitle: 'Hoạt động tư vấn việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/tu-van-viec-lam',
        },
        {
          text: 'Hoạt động tư vấn việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/tu-van-viec-lam/:type',
    component: () => import('@/views/employment-service/advise/pages/IndexTab.vue'),
    name: 'advise-employment-type',
    meta: {
      pageTitle: 'Hoạt động tư vấn việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/tu-van-viec-lam',
        },
        {
          text: 'Hoạt động tư vấn việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/nhu-cau-tuyen-dung-cua-doanh-nghiep/danh-sach',
    component: () => import('@/views/employment-service/demand/pages/List.vue'),
    name: 'demand-employment',
    meta: {
      pageTitle: 'Nhu cầu tuyển dụng của doanh nghiệp',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Nhu cầu tuyển dụng của doanh nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/dang-ki-tim-viec/danh-sach',
    component: () => import('@/views/employment-service/register/pages/List.vue'),
    name: 'register-employment',
    meta: {
      pageTitle: 'Đăng kí tìm việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Đăng kí tìm việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/dang-ki-tim-viec/them-tu-tep',
    component: () => import('@/views/employment-service/register/pages/components/ListImportFile.vue'),
    name: 'register-employment-import',
    meta: {
      pageTitle: 'Đăng kí tìm việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/dang-ki-tim-viec/danh-sach',
        },
        {
          text: 'Đăng kí tìm việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu/gioi-thieu-viec-lam/danh-sach',
    component: () => import('@/views/employment-service/recommend/pages/List.vue'),
    name: 'recommend-employment',
    meta: {
      pageTitle: 'Hoạt động giới thiệu việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Hoạt động giới thiệu việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/hoat-dong-gioi-thieu-viec-lam/nguoi-dung/:id',
    component: () => import('@/views/employment-service/recommend/pages/components/ListUser.vue'),
    name: 'recommend-employment-user',
    meta: {
      pageTitle: 'Hoạt động giới thiệu việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu/gioi-thieu-viec-lam/danh-sach',
        },
        {
          text: 'Hoạt động giới thiệu việc làm',
          to: '/dich-vu/gioi-thieu-viec-lam/danh-sach',
        },
        {
          text: 'Thêm động giới thiệu việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/dao-tao-huan-luyen/danh-sach',
    component: () => import('@/views/employment-service/training/pages/List.vue'),
    name: 'training-employment',
    meta: {
      pageTitle: 'Tổ chức đào tạo, tập huấn',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Tổ chức đào tạo, tập huấn',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/dao-tao-huan-luyen/them-tu-tep',
    component: () => import('@/views/employment-service/training/pages/components/ListImportFile.vue'),
    name: 'training-employment-import',
    meta: {
      pageTitle: 'Tổ chức đào tạo, tập huấn',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/dao-tao-huan-luyen/danh-sach',
        },
        {
          text: 'Tổ chức đào tạo, tập huấn',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/chinh-sach-bao-hiem-that-nghiep/danh-sach',
    component: () => import('@/views/employment-service/insurance/pages/List.vue'),
    name: 'insurance-employment',
    meta: {
      pageTitle: 'Chính sách bảo hiểm thất nghiệp',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Chính sách bảo hiểm thất nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/chinh-sach-bao-hiem-that-nghiep/them-tu-tep',
    component: () => import('@/views/employment-service/insurance/pages/components/ListImportFile.vue'),
    name: 'insurance-employment-import',
    meta: {
      pageTitle: 'Chính sách bảo hiểm thất nghiệp',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/chinh-sach-bao-hiem-that-nghiep/danh-sach',
        },
        {
          text: 'Chính sách bảo hiểm thất nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/ke-hoach/danh-sach',
    component: () => import('@/views/employment-service/plan/pages/List.vue'),
    name: 'plan-employment',
    meta: {
      pageTitle: 'Kế hoạch dịch vụ việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Kế hoạch dịch vụ việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/ke-hoach/tao-moi',
    component: () => import('@/views/employment-service/plan/pages/IndexTab.vue'),
    name: 'plan-employment-add',
    meta: {
      pageTitle: 'Kế hoạch dịch vụ việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/ke-hoach/danh-sach',
        },
        {
          text: 'Kế hoạch dịch vụ việc làm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dich-vu-viec-lam/ke-hoach/chinh-sua/:id',
    component: () => import('@/views/employment-service/plan/pages/IndexTab.vue'),
    name: 'plan-employment-edit',
    meta: {
      pageTitle: 'Kế hoạch dịch vụ việc làm',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
          to: '/dich-vu-viec-lam/ke-hoach/danh-sach',
        },
        {
          text: 'Kế hoạch dịch vụ việc làm',
          active: true,
        },
      ],
    },
  },
]
