export default [
  {
    path: '/quan-ly-ho-so-luu-tru/danh-sach',
    name: 'archive-record',
    component: () => import('@/views/management-archive-records/archival-records/pages/List.vue'),
    meta: {
      parent: 'archive-record',
      pageTitle: 'Quản lý hồ sơ lưu trữ',
      breadcrumb: [
        {
          text: 'Hồ sơ lưu trữ',
        },
        {
          text: 'Quản lí hồ sơ lưu trữ',
          active: true,
        },
      ],
    },
  },
]
