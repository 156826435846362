import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = {
  validWork: [],
  validNoWork: [],
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  VALIDWORKMUTATION(state, data) {
    state.validWork = data
  },
  // eslint-disable-next-line no-shadow
  VALIDNOWORKMUTATION(state, data) {
    state.validNoWork = data
  },
}

const actions = {
  // ===========================lẫu combo box vị trí công việc========================//
  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const data = JSON.stringify(id)
      const resuft = await axiosApiInstance.get('/Position/get-combobox-position', { params: { ids: data } })
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      // console.log(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async deletePositionJob({ commit }, model) {
    try {
      const data = await axiosApiInstance.post(`Position/delete-positions?positionId=${model.positionId}`, model.id)
      return data
    } catch (error) {
      return error.response.data
    }
  },
  // eslint-disable-next-line consistent-return
  async getApiExcel(xlr, data) {
    try {
      const apiExcel = await axiosApiInstance.post('/Position/import-position-from-json', data)
      if (apiExcel === 200) {
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
      return {
        oke: true,
        data: apiExcel.data.data,
      }
    } catch (e) {
      return {
        oke: true,
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
