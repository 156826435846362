import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = {
  dataVaild: [],
  dataNoValid: [],
  dataEdit: [],
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  EDIT(state, data) {
    state.dataEdit = data
  },
  // eslint-disable-next-line no-shadow
  SET_DATA_VALID(state, data) {
    state.dataVaild = data
  },
  // eslint-disable-next-line no-shadow
  SET_NO_DATA_VALID(state, data) {
    state.dataNoValid = data
  },
}

const actions = {
  // ====================================================Lấy dữ liệu theo ID ===================================
  // eslint-disable-next-line consistent-return
  async fetchdataIdST({ commit }, data) {
    try {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FETCH_DATA_ID}${data}`)
      if (resuft.status === 200) {
        commit('EDIT', resuft.data)
        return resuft.data
      }
    } catch (e) {
      return e.response
    }
  },
  //= =================================================Lấy combo box loại doanh nghiêp
  // eslint-disable-next-line consistent-return
  async fecthDataBusinessType() {
    try {
      const resuft = await axiosApiInstance.get(ConstantsApi.DATA_BUSINESS_TYPE)
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      return null
    }
  },
  //= =================================================Lấy combo box người đại diện
  // eslint-disable-next-line consistent-return
  async fecthDataOfficalProfileCombobox() {
    try {
      const resuft = await axiosApiInstance.get(ConstantsApi.DATA_OFFICAL_PROFILE_COMBOBOX)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  // ====================================lấy dữ liệu theo ID người đại diện ==================================
  // eslint-disable-next-line consistent-return
  async fetchUserId({ commit }, data) {
    try {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FETCH_USER_ID}${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthProvinces() {
    try {
      const resuft = await axiosApiInstance.get(ConstantsApi.LIST_PROVINCES)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthDistricts({ commit }, data) {
    try {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FECTH_DISTRICTS}${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthWards({ commit }, data) {
    try {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FECTH_WARDS}${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  // ============Làm việc với file=============
  // --------------dowload file
  // file mẫu
  async dowloadFile(txt) {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWLOAD_FILE, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file mẫu doanh nghiệp.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // file excel
  async downloadExcelAll() {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_EXCEL_ALL, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // ----------------import file
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.GET_API_EXCEL, data)
      if (apiExcel.status === 200) {
        commit('SET_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('SET_NO_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        message: e,
      }
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
