export default [
  {
    path: '/nguoi-lao-dong/nguoi-lao-dong-that-nghiep/danh-sach',
    name: 'work-umployed',
    component: () => import('@/views/management-workers/worker-umployed/pages/List.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Người lao động thất nghiệp',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Người lao động thất nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/nguoi-lao-dong-that-nghiep/them-tu-tep',
    name: 'work-umployed-import',
    component: () => import('@/views/management-workers/worker-umployed/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'worker',
      pageTitle: 'Người lao động thất nghiệp',
      breadcrumb: [
        {
          text: 'Người lao động',
          to: '/nguoi-lao-dong/nguoi-lao-dong-that-nghiep/danh-sach',
        },
        {
          text: 'Người lao động thất nghiệp',
          active: true,
        },
      ],
    },
  },
]
