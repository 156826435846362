export default [
  {
    path: '/nguoi-lao-dong/phan-loai-suc-khoe/danh-sach',
    name: 'health-classification',
    component: () => import('@/views/management-workers/health-classification/pages/List.vue'),
    meta: {
      parent: 'health-classification',
      pageTitle: 'Phân loại sức khỏe',
      breadcrumb: [
        {
          text: 'Danh sách phân loại',
        },
      ],
    },
  },

  {
    path: '/nguoi-lao-dong/phan-loai-suc-khoe/them-tu-tep',
    name: 'import-classification',
    component: () => import('@/views/management-workers/health-classification/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'health-classification',
      pageTitle: 'Phân loại sức khỏe',
      breadcrumb: [
        {
          text: 'Danh sách phân loại',
          to: '/nguoi-lao-dong/phan-loai-suc-khoe/danh-sach',
        },
        {
          text: 'Thêm file phân loại sức khỏe',
          active: true,
        },
      ],
    },
  },
]
