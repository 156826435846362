export default {
  // NGGƯỜI LAO ĐỘNG THẤT NGHIỆP
  API_WOKER_UMPOYED: 'UnemploymentWorker/unemploymentWorkers',
  CREATE_WOKER_UMPOYED: 'UnemploymentWorker/unemploymentWorkers',
  EDIT_WOKER_UMPOYED: 'UnemploymentWorker/unemploymentWorkers',
  DELETE_WOKER_UMPOYED: 'UnemploymentWorker/delete-unemployment-worker',
  DETAIL_WOKER_UMPOYED: 'UnemploymentWorker/unemploymentWorkers/',
  IMPORT_FILE_WOKER_UMPOYED: 'UnemploymentWorker/import-unemployment-worker-from-json',

  // COMBOBOX
  COMBOBOX_CITY: 'OfficalProfile/provinces',
  COMBOBOX_DISTRICT: 'OfficalProfile/districts/',
  COMBOBOX_WARD: 'OfficalProfile/wards/',
}
