export default [
  {
    path: '/nop-bao-cao/danh-sach',
    name: 'submit-report',
    component: () => import('@/views/submit-reports/submit-report/pages/List.vue'),
    meta: {
      pageTitle: 'Nộp báo cáo',
    },
  },
  {
    path: '/bao-cao-tong-ket-thang-hanh-dong-ve-an-toan-ve-sinh-lao-dong/danh-sach',
    name: 'final-report',
    component: () => import('@/views/submit-reports/final-report/pages/List.vue'),
    meta: {
      parent: '',
      pageTitle: 'Nộp báo cáo',
      breadcrumb: [
        {
          text: 'Báo cáo tổng kết tháng hành động về an toàn vệ sinh lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bao-cao-tong-ket-thang-hanh-dong-ve-an-toan-ve-sinh-lao-dong-so/danh-sach',
    name: 'final-report-department',
    component: () => import('@/views/submit-reports/final-report-department/pages/List.vue'),
    meta: {
      parent: '',
      pageTitle: 'Nộp báo cáo',
      breadcrumb: [
        {
          text: 'Báo cáo tổng kết tháng hành động về an toàn vệ sinh lao động',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/bao-cao-tong-ket-thang-hanh-dong-ve-an-toan-ve-sinh-lao-dong-so/danh-sach/:type',
  //   name: 'final-report-department-type',
  //   component: () => import('@/views/submit-reports/final-report-department/pages/List.vue'),
  //   meta: {
  //     parent: '',
  //     pageTitle: 'Nộp báo cáo',
  //     breadcrumb: [
  //       {
  //         text: 'Báo cáo tổng kết tháng hành động về an toàn vệ sinh lao động',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
