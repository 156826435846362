/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataAllBusiness: [],
  validWork: [],
  validNoWork: [],
  dataTooltip: [],
})

const getters = {
  dataAllBusiness: state => state.dataAllBusiness,
  validWork: state => state.validWork,
  validNoWork: state => state.validNoWork,
  dataTooltip: state => state.dataTooltip,
}

const actions = {

  // Lấy danh sách combobox những người lao động chưa cấp phép
  async fetchDataComboboxAllBusiness({ commit }, model) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_BUSINESS, { params: { isSelfEmployment: model } })
      commit('SET_DATA_LIST_COMBOBOX_ALL_BUSINESS', data)
    } catch (e) {
      return null
    }
  },

  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post('/Training/import-training-worker-from-json', data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel?.data?.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel?.data?.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },
}

const mutations = {
  SET_DATA_LIST_COMBOBOX_ALL_BUSINESS(state, data) {
    state.dataAllBusiness = data.data
  },
  DATA_VALID_USER(state, data) {
    state.validWork = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.validNoWork = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
