export default [
  {
    path: '/kiem-dinh-thiet-bi/danh-sach',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/occupational-safety-health/inspect-equip/List.vue'),
    name: 'inspect-equipment',
    meta: {
      pageTitle: 'Kiểm định thiết bị',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Kiểm định thiết bị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/them-kiem-dinh-thiet-bi',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/occupational-safety-health/inspect-equip/pages/Create.vue'),
    name: 'inspect-equipment-add',
    meta: {
      pageTitle: 'Kiểm định thiết bị',
      breadcrumb: [
        {
          text: 'Kiểm định thiết bị',
          to: '/kiem-dinh-thiet-bi/danh-sach',
        },
        {
          text: 'Thêm kiểm định thiết bị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cap-nhat-kiem-dinh-thiet-bi/:id',
    name: 'inspect-equipment-edit',
    meta: {
      pageTitle: 'Kiểm định thiết bị',
      breadcrumb: [
        {
          text: 'Kiểm định thiết bị',
          to: '/kiem-dinh-thiet-bi/danh-sach',
        },
        {
          text: 'Thêm kiểm định thiết bị',
          active: true,
        },
      ],
    },
    component: () => import('@/views/occupational-safety-health/inspect-equip/pages/Create.vue'),
  },
]
