export default {
  BUSINESS_SECTOR_TREEVIEW_UPDATED: 'BussinessSector/get-treeview-from-update',
  BUSINESS_SECTOR_TREEVIEW_ROOT: 'BussinessSector/get-treeview-root',
  BUSINESS_SECTOR_TREEVIEW_CHILDREN: 'BussinessSector/get-treeview-childRen',
  IMPORT_FILE_BUSINESS_SECTOR: 'BussinessSector/import-BussinessSector-from-json',
  EDIT_BUSINESS_SECTOR: 'BussinessSector/update',
  DELETE_BUSINESS_SECTOR: 'BussinessSector/delete',
  CREATE_BUSINESS_SECTOR: 'BussinessSector/create',
  DETAIL_BUSINESS_SECTOR: 'BussinessSector/get-by-id/',
}
