export default [
  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/tin-nhan',
    name: 'apps-email',
    component: () => import('@/views/email/pages/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',

    },
  },

  {
    path: '/tin-nhan/hop-thu-di',
    name: 'apps-email-folder',
    component: () => import('@/views/email/pages/EmailSend.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/message/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/email/pages/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

]
