export default [
  // Thu thập thông tin về cung lao động
  {
    path: '/thu-thap-thong-tin-ve-cung-lao-dong/danh-sach',
    name: 'collect-information-worker',
    component: () => import('@/views/management-collect-infor/collect-infor-worker/pages/List.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Thu thập thông tin về cung lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thu-thap-thong-tin-ve-cung-lao-dong/them-tu-tep',
    name: 'collect-information-worker-import-file',
    component: () => import('@/views/management-collect-infor/collect-infor-worker/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Thu thập thông tin về cung lao động',
        },
        {
          text: 'Thêm từ tệp',
          active: true,
        },
      ],
    },
  },

  // Thu thập thông tin về nhu cầu tuyển dụng
  {
    path: '/thu-thap-thong-tin-ve-nhu-cau-tuyen-dung/danh-sach',
    name: 'labor-recruitment',
    component: () => import('@/views/management-collect-infor/labor-recruitment/pages/List.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Thu thập thông tin về nhu cầu tuyển dụng',
          active: true,
        },
      ],
    },
  },

  // Thu thập thông tin về người lao động nước ngoài
  {
    path: '/thu-thap-thong-tin-ve-nguoi-lao-dong-nuoc-ngoai/danh-sach',
    name: 'foreign-workers',
    component: () => import('@/views/management-collect-infor/foreign-workers/pages/List.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Thu thập thông tin về người lao động nước ngoài',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thu-thap-thong-tin-ve-nguoi-lao-dong-nuoc-ngoai/them-tu-tep',
    name: 'foreign-workers-import-file',
    component: () => import('@/views/management-collect-infor/foreign-workers/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Thu thập thông tin về người lao động nước ngoài',
        },
        {
          text: 'Thêm từ tệp',
          active: true,
        },
      ],
    },
  },
  // Báo cáo thông tin thị trường lao động
  {
    path: '/bao-cao-thong-tin-thi-truong-lao-dong/danh-sach',
    name: 'labor-market-information',
    component: () => import('@/views/management-collect-infor/labor-market-information/pages/List.vue'),
    meta: {
      parent: 'collect-information-worker',
      pageTitle: 'Thu thập thông tin',
      breadcrumb: [
        {
          text: 'Báo cáo thông tin thị trường lao động',
          active: true,
        },
      ],
    },
  },
]
