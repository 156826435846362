export default [
  {
    path: '/nguoi-lao-dong/bao-cao-nguoi-lao-dong/danh-sach',
    name: 'work-report-list',
    component: () => import('@/views/management-workers/worker-reports/pages/List.vue'),
    meta: {
      parent: 'work-report',
      pageTitle: 'Báo cáo người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Báo cáo',
          active: true,
        },
      ],
    },
  },
]
