export default [
  // Đơn vị liên kết
  {
    path: '/don-vi-ket-noi/danh-sach',
    name: 'connective-unit',
    component: () => import('@/views/management-connective/pages/connective-unit/List.vue'),
    meta: {
      parent: 'connective-unit',
      pageTitle: 'Quản lý Liên kết',
      breadcrumb: [
        {
          text: 'Liên kết',
        },
      ],
    },
  },

  // Tài khoản khai thác
  {
    path: '/tai-khoan-khai-thac/danh-sach',
    name: 'account-exploit',
    component: () => import('@/views/management-connective/pages/account-exploit/List.vue'),
    meta: {
      parent: 'account-exploit',
      pageTitle: 'Tài khoản khai thác',
      breadcrumb: [
        {
          text: 'Tài khoản khai thác',
        },
      ],
    },
  },
]
