export default {
  CREATE_OCCUPATION: 'TypeOfProfession/create',
  DELETE_OCCUPATION: 'TypeOfProfession/delete',
  DETAIL_OCCUPATION: 'TypeOfProfession/get-by-id/',
  EDIT_OCCUPATION: 'TypeOfProfession/update',
  IMPORT_FILE_OCCUPATION: 'TypeOfProfession/import-from-json',
  LIST_OCCUPATION_TREEVIEW_ROOT: 'TypeOfProfession/get-treeview-root',
  LIST_OCCUPATION_TREEVIEW_CHILDREN: 'TypeOfProfession/get-treeview-childRen',
  LIST_OCCUPATION_TREEVIEW_UPDATED: 'TypeOfProfession/get-treeview-from-update',
}
