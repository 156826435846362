export default [
  {
    path: '/nguoi-lao-dong/nhom-doi-tuong-huan-luyen/danh-sach',
    name: 'group-training',
    component: () => import('@/views/management-workers/group-training/pages/List.vue'),
    meta: {
      parent: 'group-training',
      pageTitle: 'Nhóm đối tượng huấn luyện',
      breadcrumb: [
        {
          text: 'Nhóm đối tượng huấn luyện',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-lao-dong/nhom-doi-tuong-huan-luyen/theo-nhom-danh-sach/:id',
    name: 'target-group-training',
    component: () => import('@/views/management-workers/group-training/pages/components/ListTargetGroup.vue'),
    meta: {
      parent: 'group-training',
      pageTitle: 'Nhóm đối tượng huấn luyện',
      breadcrumb: [
        {
          text: 'Nhóm đối tượng huấn luyện',
          to: '/nguoi-lao-dong/nhom-doi-tuong-huan-luyen/danh-sach',
        },
        {
          text: 'Thêm người lao động',
          active: true,
        },
      ],
    },
  },
]
