export default {
  // yếu tố chấn thương
  LIST: 'AccidentSpecializeReport/accident-specialize-report',
  CREATE: 'AccidentSpecializeReport/add-accident-specialize-report',
  EDIT: 'AccidentSpecializeReport/update-accident-specialize-report',
  DELETE: 'AccidentSpecializeReport/delete-accident-specialize-report',
  DETAIL: 'AccidentSpecializeReport/accident-specialize-report/',
  //  thêm từ file và tải file mẫu
  EXPORT_FILE_TEMPLATE: 'InjuryElement/generateTemplate',
  EXPORT_FILE: 'InjuryElement/export-excel',
  IMPORT_FILE: 'InjuryElement/import-injury-element-from-json',

  // phân loại theo loại hình cơ sở
  ADD_TYPE: 'AccidentSpecializeReport/add-accident-specialize-report-type',
  EDIT_TYPE: 'AccidentSpecializeReport/update-accident-specialize-report-type',
  DELETE_TYPE: 'AccidentSpecializeReport/delete-accident-specialize-report-type',
  DETAIL_TYPE: 'AccidentSpecializeReport/accidentSpecializeReportType/',
  LIST_TYPE: 'AccidentSpecializeReport/list-accident-specialize-report-type',

  //  thêm từ file và tải file mẫu của loại hình cơ sở
  TEMPLATE_TYPE: 'AccidentSpecializeReport/accidentReportTypeGenerateTemplate',
  IMPORT_TYPE: 'AccidentSpecializeReport/import-accident-specialize-report-type-from-json',

  // TIÊU chí báo cáo
  ADD_TITLE: 'AccidentSpecializeReport/add-accident-specialize-report-title',
  EDIT_TITLE: 'AccidentSpecializeReport/update-accident-specialize-report-title',
  DELETE_TITLE: 'AccidentSpecializeReport/delete-accident-specialize-report-title',
  DETAIL_TITLE: 'AccidentSpecializeReport/accidentSpecializeReportTitle/',
  LIST_TITLE: 'AccidentSpecializeReport/list-accident-specialize-report-title',

  // thêm từ file và tải file mẫu tiêu chí báo cáo
  TEMP_TITLE: 'AccidentSpecializeReport/accidentReportTitleGenerateTemplate',
  IMPORT_TITLE: 'AccidentSpecializeReport/import-accident-specialize-report-title-from-json',

  PDF_REPORT: 'AccidentSpecializeReport/file-accident-specialize-report',
}
