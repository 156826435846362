export default [
  {
    path: '/an-toan-ve-sinh-lao-dong/su-dung-thiet-bi/danh-sach',
    component: () => import('@/views/occupational-safety-health/use-device/pages/List.vue'),
    name: 'use-device',
    meta: {
      pageTitle: 'Sử dụng thiết bị',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
          // to: '/occupational-safety-health/use-device/list',
        },
        {
          text: 'Sử dung thiết bị',
          active: true,
        },
      ],
    },
  },
]
