export default [
  {
    path: '/quan-li-don-vi-truc-thuoc/don-vi-truc-thuoc/danh-sach',
    name: 'affilliated-units',
    component: () => import('@/views/management-affilliated-units/affiliated-units/pages/List.vue'),
    meta: {
      parent: 'affilliated-units',
      pageTitle: 'Quản lý đơn vị trực thuộc',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị trực thuộc',
        },
        {
          text: 'Đơn vị trực thuộc',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quan-li-don-vi-truc-thuoc/don-vi-truc-thuoc/them-tu-tep',
    name: 'affilliated-units-import-file',
    component: () => import('@/views/management-affilliated-units/affiliated-units/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'affilliated-units',
      pageTitle: 'Quản lý đơn vị trực thuộc',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị trực thuộc',
          to: '/quan-li-don-vi-truc-thuoc/don-vi-truc-thuoc/danh-sach',
        },
        {
          text: 'Đơn vị trực thuộc',
          active: true,
        },
      ],
    },
  },
]
