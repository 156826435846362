export default [
  {
    path: '/tinh-hinh-nop-bao-cao/danh-sach',
    component: () => import('@/views/management-business/status-reports/pages/TabStatusReport.vue'),
    name: 'status-report',
    meta: {
      pageTitle: 'Tình hình nộp báo cáo',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Tình hình nộp báo cáo',
          to: '/tinh-hinh-nop-bao-cao/danh-sach',
        },
      ],
    },
  },

  // Bảng tổng hợp kiểm định thiết bị
  {
    path: '/bang-tong-hop-kiem-dinh-thiet-bi/danh-sach',
    component: () => import('@/views/report/equipment-inspection/pages/List.vue'),
    name: 'equipment-inspection',
    meta: {
      pageTitle: 'Bảng tổng hợp kiểm định thiết bị',
      breadcrumb: [
        {
          text: 'Báo cáo',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Bảng tổng hợp kiểm định thiết bị',
          to: '/bang-tong-hop-kiem-dinh-thiet-bi/danh-sach',
        },
      ],
    },
  },

  // Bảng tống hợp huấn luyện ATVSLĐ
  {
    path: '/bang-tong-hop-huan-luyen-ATVSLĐ',
    component: () => import('@/views/report/summary-training/pages/List.vue'),
    name: 'summary-training',
    meta: {
      pageTitle: 'Bảng tống hợp huấn luyện ATVSLĐ',
      breadcrumb: [
        {
          text: 'Báo cáo',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Bảng tống hợp huấn luyện ATVSLĐ',
          to: '/bang-tong-hop-huan-luyen-ATVSLĐ',
        },
      ],
    },
  },
]
