/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = {
  alertAddUserGroup: '',
  validWork: [],
  validNoWork: [],
  dataEdit: '',
  dataTooltip: [],
}

const getters = {
  validWork: state => state.validWork,
  validNoWork: state => state.validNoWork,
  dataTooltip: state => state.dataTooltip,
}

const mutations = {
  // eslint-disable-next-line no-shadow
  ALERT_ADD_USER_GROUP(state, data) {
    state.alertAddUserGroup = data
  },
  DATA_VALID_USER(state, data) {
    state.validWork = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.validNoWork = data
  },
  // eslint-disable-next-line no-shadow
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
  // eslint-disable-next-line no-shadow
  DATA_EDIT(state, data) {
    state.dataEdit = data
  },
}

const actions = {
  // =============================== download file mẫu về máy ===============================================
  // eslint-disable-next-line consistent-return
  async downloadSampleUserGroup() {
    try {
      axiosApiInstance({
        url: '/BussinessType/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiHinhDoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return null
    }
  },
  // =============================== download file excel về máy ===============================================
  // eslint-disable-next-line consistent-return
  async downloadExcelAll() {
    try {
      axiosApiInstance({
        url: '/BussinessType/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiHinhDoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return null
    }
  },
  //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.GET_API_EXCEL, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
    } catch (e) {
      return {
        oke: false,
        messageErr: e,
      }
    }
  },

  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const data = JSON.stringify(id)
      const resuft = await axiosApiInstance.get('/BussinessType/get-combobox-bussiness-type', { params: { ids: data } })
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      return null
    }
  },
  // eslint-disable-next-line consistent-return
  async deleteUserGroup({ commit }, model) {
    try {
      const data = await axiosApiInstance.post(`BussinessType/delete-bussinessTypes?positionId=${model.positionId}`, model.id)
      return data
    } catch (error) {
      return null
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
