export default [
  {
    path: '/khai-bao-thiet-bi/danh-sach',
    component: () => import('@/views/occupational-safety-health/declare-equip/List.vue'),
    name: 'declare-equipment',
    meta: {
      pageTitle: 'Khai báo thiết bị',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
          // to: '#',
        },
        {
          text: 'Khai báo thiết bị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/them-khai-bao-thiet-bi',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/occupational-safety-health/declare-equip/pages/Create.vue'),
    name: 'declare-equipment-add',
    meta: {
      pageTitle: 'Khai báo thiết bị',
      breadcrumb: [
        {
          text: 'Khai báo thiết bị',
          active: true,
        },
        {
          text: 'Thêm thiết bị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cap-nhat-khai-bao-thiet-bi/:id',
    name: 'declare-equipment-edit',

    meta: {
      pageTitle: 'Khai báo thiết bị',
      breadcrumb: [
        {
          text: 'Khai báo thiết bị',
          active: true,
        },
        {
          text: 'Sửa thông tin thiết bị',
          active: true,
        },
      ],
    },
    component: () => import('@/views/occupational-safety-health/declare-equip/pages/Create.vue'),

  },
  // {
  //   path: '/business/List',
  //   component: () => import('@/views/management-business/business/pages/components/ListImportFile.vue'),
  //   name: 'business-list-add',
  //   meta: {
  //     pageTitle: 'Doanh nghiệp',
  //     breadcrumb: [
  //       {
  //         text: 'Doanh nghiệp',
  //         to: '/business',
  //       },
  //       {
  //         text: 'Danh sách doanh nghiệp',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
