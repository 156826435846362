export default [
  {
    path: '/nguoi-dung/danh-sach',
    name: 'list-user',
    component: () => import('@/views/management-user/users/pages/List.vue'),
    meta: {
      parent: 'list-user',
      pageTitle: 'Danh sách người dùng',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/chinh-sua/:id',
    name: 'edit-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Sửa thông tin người dùng',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/nguoi-dung/danh-sach',
        },
        {
          text: 'Sửa thông tin',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/Create.vue'),
  },
  {
    path: '/thong-tin-tai-khoan/:id',
    name: 'info-user',
    meta: {
      pageTitle: 'Thông tin tài khoản',
      // breadcrumb: [
      // {
      //   text: 'Thông tin tài khoản',
      //   to: '/nguoi-dung/danh-sach',
      // },
      // {
      //   text: 'Sửa thông tin',
      //   active: true,
      // },
      // ],
    },
    component: () => import('@/views/management-user/users/pages/Create.vue'),
  },
  {
    path: '/nguoi-dung/tao-moi',
    name: 'create-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Thêm người dùng',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/nguoi-dung/danh-sach',
        },
        {
          text: 'Thêm người dùng',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/Create.vue'),
  },
  {
    path: '/nguoi-dung/them-tu-tep',
    name: 'import-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Thêm người dùng từ file',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/nguoi-dung/danh-sach',
        },
        {
          text: 'Thêm người dùng',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/components/ListImportFile.vue'),
  },

  // Thông tin đơn vị
  {
    path: '/nguoi-dung/thong-tin-don-vi',
    name: 'infomation-unit',
    meta: {
      parent: 'infomation-unit',
      pageTitle: 'Thông tin đơn vị',
      breadcrumb: [
        {
          text: 'Thông tin đơn vị',
        },
      ],
    },
    component: () => import('@/views/management-user/infomation-unit/InfomationUnit.vue'),
  },
  {
    path: '/nguoi-dung/thong-tin-don-vi/phong',
    name: 'infomation-unit-education',
    meta: {
      parent: 'infomation-unit-education',
      pageTitle: 'Thông tin đơn vị',
      breadcrumb: [
        {
          text: 'Thông tin đơn vị',
        },
      ],
    },
    component: () => import('@/views/management-user/infomation-unit/InfomationUnitEducation.vue'),
  },
]
