/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import axios from '@axios'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataList: [],
  dataListChildren: [],
  dataListUpdated: [],
})

const getters = {
  dataList: state => state.dataList,
  dataListChildren: state => state.dataListChildren,
  dataListUpdated: state => state.dataListUpdated,
}

const actions = {

  // Gọi dánh sách cơ cấu tổ chức cha
  async doFetchList({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.TREE_ROOT_LIGHT, { params: payload })
      commit('SET_DATA_LIST', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức con
  async doFetchChildren({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.CHILDREN_LIGHT, { params: payload })
      commit('SET_DATA_CHILDREN', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức cần thêm mới
  async doFetchUpdated({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_TREEVIEW_UPDATE_LIGHT, { params: payload })
      commit('SET_DATA_UPDATED', data)
    } catch (e) {
      return null
    }
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_DATA_LIST(state, data) {
    state.dataList = data?.data
  },
  SET_DATA_CHILDREN(state, data) {
    state.dataListChildren = data?.data
  },

  SET_DATA_UPDATED(state, data) {
    state.dataListUpdated = data?.data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
