/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import JwtService from './jwtService'

export default function useJwt(jwtOverrideConfig) {
  const jwt = new JwtService(jwtOverrideConfig)
  return {
    jwt,
  }
}
