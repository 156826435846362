export default [
  {
    path: '/thiet-bi-kiem-dinh/danh-sach',
    name: 'safetyClassification',
    component: () => import('@/views/safety-classification/pages/List.vue'),
    meta: {
      parent: 'safetyClassification',
      pageTitle: 'Doanh mục thiết bị kiểm định',
      breadcrumb: [
        {
          text: 'An toàn vệ sinh lao động',
        },
        {
          text: 'Phân loại tai nạn lao động',
          active: true,
        },
      ],
    },
  },
]
