export default {
  // Mức độ bụi
  API_DUST: 'MenuObservationMonitor/get-paging-DustLevel',
  CREATE_DUST: 'MenuObservationMonitor/create-DustLevel',
  EDIT_DUST: 'MenuObservationMonitor/update-DustLevel',
  DELETE_DUST: 'MenuObservationMonitor/delete-DustLevel',
  DETAIL_DUST: 'MenuObservationMonitor/get-by-id-DustLevel/',
  COMBOBOX_DUST: 'MenuObservationMonitor/get-combobox-DustLevel',

  // Điện từ trường tần số công nghiệp
  API_MAGNETICLEVEL: 'MenuObservationMonitor/get-paging-MagneticLevel',
  CREATE_MAGNETICLEVEL: 'MenuObservationMonitor/create-MagneticLevel',
  EDIT_MAGNETICLEVEL: 'MenuObservationMonitor/update-MagneticLevel',
  DELETE_MAGNETICLEVEL: 'MenuObservationMonitor/delete-MagneticLevel',
  DETAIL_MAGNETICLEVEL: 'MenuObservationMonitor/get-by-id-MagneticLevel/',

  // Tiếng ồn
  API_NOISE: 'MenuObservationMonitor/get-paging-NoiseLevel',
  CREATE_NOISE: 'MenuObservationMonitor/create-NoiseLevel',
  EDIT_NOISE: 'MenuObservationMonitor/update-NoiseLevel',
  DELETE_NOISE: 'MenuObservationMonitor/delete-NoiseLevel',
  DETAIL_NOISE: 'MenuObservationMonitor/get-by-id-NoiseLevel/',
  COMBOBOX_NOISE: 'MenuObservationMonitor/get-combobox-NoiseLevel',

  // Bức xạ tia x
  API_RADITION: 'MenuObservationMonitor/get-paging-RadiationLevel',
  CREATE_RADITION: 'MenuObservationMonitor/create-RadiationLevel',
  EDIT_RADITION: 'MenuObservationMonitor/update-RadiationLevel',
  DELETE_RADITION: 'MenuObservationMonitor/delete-RadiationLevel',
  DETAIL_RADITION: 'MenuObservationMonitor/get-by-id-RadiationLevel/',
  COMBOBOX_RADITION: 'MenuObservationMonitor/get-combobox-RadiationLevel',

  // Nhiệt độ
  API_TEMPERATURE: 'MenuObservationMonitor/get-paging-TemperatureLevel',
  CREATE_TEMPERATURE: 'MenuObservationMonitor/create-TemperatureLevel',
  EDIT_TEMPERATURE: 'MenuObservationMonitor/update-TemperatureLevel',
  DELETE_TEMPERATURE: 'MenuObservationMonitor/delete-TemperatureLevel',
  DETAIL_TEMPERATURE: 'MenuObservationMonitor/get-by-id-TemperatureLevel/',
  COMBOBOX_TYPE_OF_LABORLEVEL: 'MenuObservationMonitor/get-combobox-TypeOfLaborLevel',

  // Hóa chất
  API_CHEMISTRY: 'MenuObservationMonitor/get-paging-Chemistry',
  CREATE_CHEMISTRY: 'MenuObservationMonitor/create-Chemistry',
  EDIT_CHEMISTRY: 'MenuObservationMonitor/update-Chemistry',
  DELETE_CHEMISTRY: 'MenuObservationMonitor/delete-Chemistry',
  DETAIL_CHEMISTRY: 'MenuObservationMonitor/get-by-id-Chemistry/',

  // Hóa chất
  API_ANOTHER: 'MenuObservationMonitor/get-paging-Another',
  CREATE_ANOTHER: 'MenuObservationMonitor/create-Another',
  EDIT_ANOTHER: 'MenuObservationMonitor/update-Another',
  DELETE_ANOTHER: 'MenuObservationMonitor/delete-Another',
  DETAIL_ANOTHER: 'MenuObservationMonitor/get-by-id-Another/',

  //  Rung
  API_VIBRATELEVEL: 'MenuObservationMonitor/get-paging-vibrateLevel',
  CREATE_VIBRATELEVEL: 'MenuObservationMonitor/create-vibrateLevel',
  EDIT_VIBRATELEVEL: 'MenuObservationMonitor/update-vibrateLevel',
  DELETE_VIBRATELEVEL: 'MenuObservationMonitor/delete-vibrateLevel',
  DETAIL_VIBRATELEVEL: 'MenuObservationMonitor/get-by-id-vibrateLevel/',
  COMBOBOX_VIBRATELEVEL: 'MenuObservationMonitor/get-combobox-VibrateLevel',

  // Ánh sáng
  CREATE_LIGHT: 'MenuObservationMonitor/create-light',
  EDIT_LIGHT: 'MenuObservationMonitor/update-light',
  DELETE_LIGHT: 'MenuObservationMonitor/delete-light',
  DETAIL_LIGHT: 'MenuObservationMonitor/get-by-id-light/',
  COMBOBOX_LIGHT: 'MenuObservationMonitor/get-combobox-VibrateLevel',
  TREE_ROOT_LIGHT: 'MenuObservationMonitor/get-treeview-root-light',
  CHILDREN_LIGHT: 'MenuObservationMonitor/get-treeview-childRen-light',
  GET_TREEVIEW_UPDATE_LIGHT: 'MenuObservationMonitor/get-treeview-from-update-light',
  GET_TREEVIEW_LIGHT: 'MenuObservationMonitor/get-treeview-light',
}
