export default [
  {
    path: '/doanh-nghiep/danh-sach',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/management-business/business/pages/List.vue'),
    name: 'business',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Danh sách doanh nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/them-doanh-nghiep',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/management-business/business/pages/Add.vue'),
    name: 'business-add',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Thêm doanh nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-tin-doanh-nghiep',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/management-business/business-user/pages/Add.vue'),
    name: 'business-add-orther',
    meta: {
      pageTitle: 'Thông tin doanh nghiệp',
      breadcrumb: [
        {
          text: 'Thông tin doanh nghiệp',
          to: '/thong-tin-doanh-nghiep',
        },
      ],
    },
  },
  {
    path: '/cap-nhat-doanh-nghiep/:id',
    name: 'business-edit',
    meta: {
      pageTitle: 'Sửa thông tin doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Sửa Doanh nghiệp',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-business/business/pages/Add.vue'),
  },
  {
    path: '/them-tu-file-danh-sach-doanh-nghiẹp',
    component: () => import('@/views/management-business/business/pages/components/ListImportFile.vue'),
    name: 'business-list-add',
    meta: {
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Danh sách doanh nghiệp',
          active: true,
        },
      ],
    },
  },
]
