export default [
  {
    path: '/danh-muc-ho-so/danh-sach',
    name: 'category-file',
    component: () => import('@/views/management-archive-records/category-file/pages/List.vue'),
    meta: {
      parent: 'category-file',
      pageTitle: 'Danh mục hồ sơ',
      breadcrumb: [
        {
          text: 'Hồ sơ lưu trữ',
        },
        {
          text: 'Danh mục hồ sơ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/danh-muc-ho-so/them-tu-tep',
    name: 'category-import-file',
    component: () => import('@/views/management-archive-records/category-file/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'category-file',
      pageTitle: 'Danh mục hồ sơ',
      breadcrumb: [
        {
          text: 'Danh mục hồ sơ',
          to: '/danh-muc-ho-so/danh-sach',
        },
        {
          text: 'Quản lí hồ sơ lưu trữ',
          active: true,
        },
      ],
    },
  },
]
