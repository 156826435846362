import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = {
  dataEdit: {},
  dataVaild: [],
  dataNoValid: [],
  dataCombobox: [],
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  EDIT(state, data) {
    state.dataEdit = data
  },
  // eslint-disable-next-line no-shadow
  COMBOBOX_TYPE(state, data) {
    state.dataCombobox = data.data
  },
  // eslint-disable-next-line no-shadow
  SET_DATA_VALID(state, data) {
    state.dataVaild = data
  },
  // eslint-disable-next-line no-shadow
  SET_NO_DATA_VALID(state, data) {
    state.dataNoValid = data
  },
}

const actions = {
  // lấy dữ liệu theo ID
  // eslint-disable-next-line consistent-return
  async fetchdataIdST({ commit }, data) {
    try {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FETCH_DATA_ID_ST}${data}`)
      if (resuft.status === 200) {
        commit('EDIT', resuft.data)
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },

  // combobox danh mục nghề nghiệp
  // eslint-disable-next-line consistent-return
  async fetchdataTypeOfProfession({ commit }) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPEOFPROFESSION)
      commit('COMBOBOX_TYPE', data)
    } catch (e) {
      // console.log(e)
    }
  },
  // =Lấy danh sách tỉnh===//
  // eslint-disable-next-line consistent-return
  async fecthCountry() {
    try {
      const resuft = await axiosApiInstance.get(ConstantsApi.FECTH_COUNTRY)
      if (resuft.status === 200) {
        return {
          oke: true,
          data: resuft.data,
        }
      }
      return {
        oke: false,
        data: null,
      }
    } catch (e) {
      return {
        oke: true,
      }
    }
  },
  // ============Làm việc với file=============
  // --------------dowload file
  // file mẫu
  async dowloadFile(txt) {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWLOAD_FILE, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'FileMauNguoiLaoDong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // file excel
  async downloadExcelAll() {
    try {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_EXCEL_ALL, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NguoiLaoDong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // ----------------import file
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.GET_API_EXCEL, data)
      if (apiExcel.status === 200) {
        commit('SET_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('SET_NO_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        message: e,
      }
    }
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
