export default {
  LIST_WORKERS_PERMIT: 'WorkerLicense/workerLicenses',
  LIST_COMBOBOX_PROFILE: 'OfficalProfile/get-combobox-offical-profile',
  LIST_COMBOBOX_WORKER_NOT_REGISTER: 'Worker/get-combobox-worker-not-register',
  CREATE_REQUIRE_LICENSE: 'WorkerLicense/add-require-license',
  CREATE_REQUIRE_COMFIRM: 'WorkerLicense/add-require-confirm',
  EDIT_REQURIE_LICENSE: 'WorkerLicense/edit-require-license',
  EDIT_REQURIE_COMFIRM: 'WorkerLicense/edit-require-confirm',
  DETAIL_WORKER_LICENSE: 'WorkerLicense/worker-license/',
  DETAIL_WORKER_COMFIRM: 'WorkerLicense/worker-confirm/',
  DELETE_WORKER_LICENSE: 'WorkerLicense/delete-worker-license',
  LIST_WORKER_ADD_FILE: 'WorkerLicense/list-file-in-worker-license',
  ADD_FILE_TO_LICENSE: 'WorkerLicense/add-file-to-license',
  DELETE_FILE_TO_LICENSE: 'WorkerLicense/delete-file-in-license',
  LIST_COMBOBOX_ALL_WORKER: 'Worker/get-combobox-worker',
  REQUIRE_EXTEND: 'WorkerLicense/require-extend',
  REQUIRE_RENEW: 'WorkerLicense/require-renew',
  LIST_FILE_REQUIRES: 'WorkerLicense/list-file-requires',
  UPDATE_FILE_REQUIRES: 'WorkerLicense/update-file-require',
  DOWNLOAD_FILE_REQUIRES: 'WorkerLicense/download-file-require',
  DELETE_FILE_REQUIRES: 'WorkerLicense/delete-file-require',
}
