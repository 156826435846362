export default {
  // yếu tố chấn thương
  LIST: 'InjuryElement/injuryElements',
  CREATE: 'InjuryElement/injuryElements',
  EDIT: 'InjuryElement/injuryElements',
  DELETE: 'InjuryElement/delete-injury-elements',
  DETAIL: 'InjuryElement/injuryElements/',
  // file
  EXPORT_FILE_TEMPLATE: 'InjuryElement/generateTemplate',
  EXPORT_FILE: 'InjuryElement/export-excel',
  IMPORT_FILE: 'InjuryElement/import-injury-element-from-json',
}
