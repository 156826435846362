export default {
  // LOẠI CHẤN THƯƠNG
  API_ACCOUNT_LINKED: 'AccountLinked/accountLinkeds',
  CREATE_ACCOUNT_LINKED: 'AccountLinked/accountLinkeds',
  EDIT_ACCOUNT_LINKED: 'AccountLinked/accountLinkeds',
  DELETE_ACCOUNT_LINKED: 'AccountLinked/delete-account-linked',
  DETAIL_ACCOUNT_LINKED: 'AccountLinked/accountLinkeds/',
  IMPORT_FILE_ACCOUNT_LINKED: 'AccountLinked/import-account-linked-json',

  // COMBOBOX
  COMBOBOX_AFFILIATE: 'AffiliatedUnit/get-combobox-affiliated-unit',
  COMBOBOX_TYPE: 'UserType/get-combobox-user-type',

  // Kích hoạt tài khoản
  ACTIVE_ACCOUNT: 'Account/active-account/',
  UNACTIVE_ACCOUNT: 'Account/disable-account/',

  // Đổi mật khẩu
  CHANGE_PASSWORD: 'Account/reissue-password',
}
