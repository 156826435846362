/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataOfficalProfile: [],
  dataWorkerNotRegister: [],
  dataAllWorker: [],
})

const getters = {
  dataOfficalProfile: state => state.dataOfficalProfile,
  dataWorkerNotRegister: state => state.dataWorkerNotRegister,
  dataAllWorker: state => state.dataAllWorker,
}

const actions = {
  // Lấy danh sách combobox những người nộp hồ sơ
  async fetchDataComboboxProfile({ commit }) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_PROFILE)
      commit('SET_DATA_LIST_COMBOBOX_PROFILE', data)
    } catch (e) {
      return null
    }
  },

  // Lấy danh sách combobox những người lao động chưa cấp phép
  async fetchDataComboboxWorker({ commit }, id) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_WORKER_NOT_REGISTER, { params: { workerId: id } })
      commit('SET_DATA_LIST_COMBOBOX_WORKER', data)
    } catch (e) {
      return null
    }
  },

  // Lấy danh sách combobox những người lao động chưa cấp phép
  async fetchDataComboboxAllWorker({ commit }, id) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_ALL_WORKER, { params: { bussinessId: id } })
      commit('SET_DATA_LIST_COMBOBOX_ALL_WORKER', data)
    } catch (e) {
      return null
    }
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_DATA_LIST_COMBOBOX_PROFILE(state, data) {
    state.dataOfficalProfile = data.data
  },
  SET_DATA_LIST_COMBOBOX_WORKER(state, data) {
    state.dataWorkerNotRegister = data.data
  },
  SET_DATA_LIST_COMBOBOX_ALL_WORKER(state, data) {
    state.dataAllWorker = data.data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
