export default [
  {
    path: '/nguoi-dung/co-cau-to-chuc/danh-sach',
    name: 'list-struct',
    component: () => import('@/views/management-user/organization-struct/pages/List.vue'),
    meta: {
      parent: 'list-struct',
      pageTitle: 'Danh sách cơ cấu tổ chức',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Danh sác cơ cấu tổ chức',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/co-cau-to-chuc/them-tu-tep',
    name: 'import-struct',
    component: () => import('@/views/management-user/organization-struct/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'list-struct',
      pageTitle: 'Danh sách cơ cấu tổ chức',
      breadcrumb: [
        {
          text: 'Danh sách cơ cấu tổ chức',
          to: '/nguoi-dung/co-cau-to-chuc/danh-sach',
        },
        {
          text: 'Thêm file cơ cấu tổ chức',
          active: true,
        },
      ],
    },
  },
]
