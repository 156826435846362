export default [
  {
    path: '/thong-ke/do-tuoi-lao-dong/danh-sach',
    name: 'statistical-worker-age',
    component: () => import('@/views/management-statistical/working-age/pages/List.vue'),
    meta: {
      parent: 'statistical-worker-age',
      pageTitle: 'Toàn tỉnh trong độ tuổi lao động',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh trong độ tuổi lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/nganh-nghe/danh-sach',
    name: 'statistical-worker-career',
    component: () => import('@/views/management-statistical/worker-career/pages/List.vue'),
    meta: {
      parent: 'statistical-worker-career',
      pageTitle: 'Toàn tỉnh theo ngành nghề',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh theo ngành nghề',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/co-cau-tuoi-va-gioi-tinh/danh-sach',
    name: 'statistical-gender-structure',
    component: () => import('@/views/management-statistical/statistical-gender-structure/pages/List.vue'),
    meta: {
      parent: 'statistical-gender-structure',
      pageTitle: 'Toàn tỉnh trong độ tuổi lao động',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh trong độ tuổi lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/muc-thu-nhap/danh-sach',
    name: 'statistical-income-level',
    component: () => import('@/views/management-statistical/statistical-income-level/pages/List.vue'),
    meta: {
      parent: 'statistical-income-level',
      pageTitle: 'Toàn tỉnh theo mức thu nhập',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh theo mức thu nhập',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/cac-truong-hop-rui-ro/danh-sach',
    name: 'statistical-accident',
    component: () => import('@/views/management-statistical/statistical-accident/pages/List.vue'),
    meta: {
      parent: 'statistical-accident',
      pageTitle: 'Các trường hợp rủi ro , tai nạn lao động',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Các trường hợp rủi ro , tai nạn lao động',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/dang-lam-viec-o-tinh/danh-sach',
    name: 'statistical-work-ThaiNguyen',
    component: () => import('@/views/management-statistical/statistical-work-ThaiNguyen/pages/List.vue'),
    meta: {
      parent: 'statistical-work-ThaiNguyen',
      pageTitle: 'Đang làm việc tại Tỉnh',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Đang làm việc tại Thái Nguyên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/benh-nghe-nghiep-moi/danh-sach',
    name: 'statistical-illness-new',
    component: () => import('@/views/management-statistical/statistical-illness-new/pages/List.vue'),
    meta: {
      parent: 'statistical-illness-new',
      pageTitle: 'Số người bị bệnh nghề nghiệp mới',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Số người bị bệnh nghề nghiệp mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/benh-nghe-nghiep-cu/danh-sach',
    name: 'statistical-illness-old',
    component: () => import('@/views/management-statistical/statistical-illness-old/pages/List.vue'),
    meta: {
      parent: 'statistical-illness-old',
      pageTitle: 'Số người bị bệnh nghề nghiệp cũ',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Số người bị bệnh nghề nghiệp cũ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/chi-phi-benh-nghe-nghiep/danh-sach',
    name: 'statistical-cost-illness',
    component: () => import('@/views/management-statistical/statistical-cost-illness/pages/List.vue'),
    meta: {
      parent: 'statistical-cost-illness',
      pageTitle: 'Chi phí cho người bệnh nghề nghiệp',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Chi phí cho người bệnh nghề nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/lam-viec-tai-nuoc-ngoai/danh-sach',
    name: 'statistical-work-world',
    component: () => import('@/views/management-statistical/statistical-work-world/pages/List.vue'),
    meta: {
      parent: 'statistical-work-world',
      pageTitle: 'Đang làm việc tại nước ngoài',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Đang làm việc tại nước ngoài',
          active: true,
        },
      ],
    },
  },
  // hành chính cấp xã,huyện
  {
    path: '/thong-ke/don-vi-hanh-chinh/danh-sach',
    name: 'statistical-administrative',
    component: () => import('@/views/management-statistical/statistical-administrative/pages/List.vue'),
    meta: {
      parent: 'statistical-administrative',
      pageTitle: 'Theo đơn vị hành chính cấp xã,huyện',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Theo đơn vị hành chính cấp xã,huyện',
          active: true,
        },
      ],
    },
  },
  // theo đơn vị sử dụng lao động
  {
    path: '/thong-ke/don-vi-su-dung-lao-dong/danh-sach',
    name: 'statistical-use-labor',
    component: () => import('@/views/management-statistical/statistical-use-labor/pages/List.vue'),
    meta: {
      parent: 'statistical-use-labor',
      pageTitle: 'Theo đơn vị sử dụng lao động',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Theo đơn vị sử dụng lao động',
          active: true,
        },
      ],
    },
  },
  // toàn tỉnh được đóng bảo hiểm xã hội , bảo hiểm y tế
  {
    path: '/thong-ke/bhyt-bhxh/danh-sach',
    name: 'statistical-bhxh-bhyt',
    component: () => import('@/views/management-statistical/statistical-bhxh-bhyt/pages/List.vue'),
    meta: {
      parent: 'statistical-bhxh-bhyt',
      pageTitle: 'Toàn tỉnh được đóng BHXH,BHYT',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh được đóng BHXH,BHYT',
          active: true,
        },
      ],
    },
  },
  // toàn tỉnh đang trong tình trạng thất nghiệp
  {
    path: '/thong-ke/nguoi-lao-dong-that-nghiep/danh-sach',
    name: 'statistical-unemployment',
    component: () => import('@/views/management-statistical/statistical-unemployment/pages/List.vue'),
    meta: {
      parent: 'statistical-unemployment',
      pageTitle: 'Toàn tỉnh đang trong tình trạng thất nghiệp',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Toàn tỉnh đang trong tình trạng thất nghiệp',
          active: true,
        },
      ],
    },

  },
  {
    path: '/thong-ke/so-luong-thiet-bi/danh-sach',
    name: 'quantity-device',
    component: () => import('@/views/management-statistical/statistical-quantity-device/pages/List.vue'),
    meta: {
      parent: 'quantity-device',
      pageTitle: 'Số lượng thiết bị hiện có',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Số lượng thiết bị hiện có',
          active: true,
        },
      ],
    },
  },

  {
    path: '/thong-ke/thiet-bi-kiem-dinh/danh-sach',
    name: 'equipment-accreditation',
    component: () => import('@/views/management-statistical/statistical-equipment-accreditation/pages/List.vue'),
    meta: {
      parent: 'equipment-accreditation',
      pageTitle: 'Số lượng thiết bị đã kiểm định',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Số lượng thiết bị đã kiểm định',
          active: true,
        },
      ],
    },
  },

  {
    path: '/thong-ke/thiet-bi-khai-bao/danh-sach',
    name: 'equipment-declare',
    component: () => import('@/views/management-statistical/statistical-equipment-declare/pages/List.vue'),
    meta: {
      parent: 'equipment-declare',
      pageTitle: 'Số lượng thiết bị đã khai báo',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Số lượng thiết bị đã khai báo',
          active: true,
        },
      ],
    },
  },

  {
    path: '/thong-ke/cac-truong-hop-rui-ro-tai-nan-theo-nghe-nghiep/danh-sach',
    name: 'accident-worker-by-job',
    component: () => import('@/views/management-statistical/statistical-accident-worker-by-job/pages/List.vue'),
    meta: {
      parent: 'accident-worker-by-job',
      pageTitle: 'Thống kê',
      breadcrumb: [
        {
          text: 'Số lượng lao động bị các trường hợp rủi ro, tai nạn lao động theo nghề nghiệp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/nguoi-lao-dong-nuoc-ngoai-dang-lam-viec-tai-tinh/danh-sach',
    name: 'worker-foriegn-in-department',
    component: () => import('@/views/management-statistical/statistical-worker-foriegn/pages/List.vue'),
    meta: {
      parent: 'accident-worker-by-job',
      pageTitle: 'Thống kê',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Người lao động nước ngoài',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/doanh-nghiep-su-dung-thiet-bi/danh-sach',
    name: 'bussiness-using-equipment',
    component: () => import('@/views/management-statistical/statical-bussiness-using-equipment/pages/List.vue'),
    meta: {
      parent: 'accident-worker-by-job',
      pageTitle: 'Thống kê',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Doanh nghiệp sử dụng thiết bị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/danh-sach-thiet-bi-kiem-dinh/danh-sach',
    name: 'accreditation-equipment',
    component: () => import('@/views/management-statistical/statical-accreditation-equipment/pages/List.vue'),
    meta: {
      parent: 'accreditation-equipment',
      pageTitle: 'Thống kê',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'Thiết bị kiểm định',
          active: true,
        },
      ],
    },
  },
  {
    path: '/thong-ke/thong-ke-nguoi-lao-dong-theo-trinh-do-hoc-van/danh-sach',
    name: 'worker-by-academic-level',
    component: () => import('@/views/management-statistical/statical-worker-by-academic-level/pages/List.vue'),
    meta: {
      parent: 'worker-by-academic-level',
      pageTitle: 'Thống kê',
      breadcrumb: [
        {
          text: 'Thống kê',
        },
        {
          text: 'số lượng toàn tỉnh theo trình độ lao động ',
          active: true,
        },
      ],
    },
  },

]
