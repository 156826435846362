export default [
  {
    path: '/nop-bao-cao-dich-vu-lam-viec/danh-sach',
    name: 'employment-service',
    component: () => import('@/views/employment-service/report-service/pages/List.vue'),
    meta: {
      parent: 'employment-service',
      pageTitle: 'Nộp báo cáo',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Nộp báo cáo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nop-bao-ca/danh-sach',
    name: 'employment-service-education',
    component: () => import('@/views/employment-service/report-service-education/pages/List.vue'),
    meta: {
      parent: 'employment-service-education',
      pageTitle: 'Nộp báo cáo',
      breadcrumb: [
        {
          text: 'Dịch vụ việc làm',
        },
        {
          text: 'Nộp báo cáo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marvel',
    name: 'marvel',
    component: () => import('@/views/add-menu-role/pages/AddMenu.vue'),
    meta: {
      parent: 'marvel',
      pageTitle: 'marvel',
      breadcrumb: [
        {
          text: 'Marvel',
        },
      ],
    },
  },
  {
    path: '/meet',
    name: 'meet',
    component: () => import('@/views/add-menu-role/pages/MeetingFake.vue'),
    meta: {
      parent: 'marvel',
      pageTitle: 'marvel',
      breadcrumb: [
        {
          text: 'Marvel',
        },
      ],
    },
  },
  {
    path: '/marvel/list',
    name: 'marvel-list',
    component: () => import('@/views/add-menu-role/pages/ListMenu.vue'),
    meta: {
      parent: 'marvel-list',
      pageTitle: 'marvel-list',
      breadcrumb: [
        {
          text: 'Marvel',
        },
      ],
    },
  },
]
