import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = {
  emails: [
    {
      id: 1,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 2,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 2',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 3,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 3',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 4,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 4',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'inbox',
      isRead: true,
    },
    {
      id: 4,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 5',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 4,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 6',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'sent',
      isRead: true,
    },
    {
      id: 10,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 7',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'trash',
      isRead: true,
    },
    {
      id: 9,
      from: {
        email: 'Hoaphat@mail.com',
        name: 'Công ty Hòa Phát 9',
        avatar: require('@/assets/images/avatars/1.png'),
      },
      to: [
        {
          name: 'Công ty A',
          email: 'congtyA@mail.com',
        },
      ],
      subject: 'Đề nghị cấp phép huấn luận an toàn lao động',
      cc: [],
      bcc: [],
      message:
        '<p>Chào bạn,</p><p>Doanh nghiệp Công Ty Hòa Phát vừa nạp đề nghị cấp giấy phép huấn luyện an toàn lao động vào hệ thống đang chờ bạn xem . Để xem đề nghị vui lòng truy cập vào     </br> </br> <a href="#css" >Huấn luyện an toàn lao động</a> </p>',
      attachments: [
      ],
      isStarred: false,
      // labels: ['private'],
      time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
      replies: [],
      folder: 'draft',
      isRead: true,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/email/emails').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', folder = 'inbox', label } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()

  function isInFolder(email) {
    if (folder === 'trash') return email.folder === 'trash'
    if (folder === 'starred') return email.isStarred && email.folder !== 'trash'
    return email.folder === (folder || email.folder) && email.folder !== 'trash'

    // email.folder === (folder || email.folder)

    // if (filter === 'important') return task.isImportant && !task.isDeleted
    // if (filter === 'completed') return task.isCompleted && !task.isDeleted
    // if (filter === 'deleted') return task.isDeleted
    // return !task.isDeleted
  }

  const filteredData = data.emails.filter(
    email =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      email.subject.toLowerCase().includes(queryLowered) &&
      isInFolder(email) &&
      (label ? email.labels.includes(label) : true),
  )
  /* eslint-enable  */

  // ------------------------------------------------
  // Email Meta
  // ------------------------------------------------
  const emailsMeta = {
    inbox: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'inbox').length,
    draft: data.emails.filter(email => email.folder === 'draft').length,
    spam: data.emails.filter(email => !email.isDeleted && !email.isRead && email.folder === 'spam').length,
  }

  return [
    200,
    {
      emails: filteredData.reverse(),
      emailsMeta,
    },
  ]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/email/update-emails').reply(config => {
  const { emailIds, dataToUpdate } = JSON.parse(config.data)

  function updateMailData(email) {
    Object.assign(email, dataToUpdate)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailData(email)
  })

  return [200]
})

// ------------------------------------------------
// POST: Update Emails Label
// ------------------------------------------------
mock.onPost('/apps/email/update-emails-label').reply(config => {
  const { emailIds, label } = JSON.parse(config.data)

  function updateMailLabels(email) {
    const labelIndex = email.labels.indexOf(label)

    if (labelIndex === -1) email.labels.push(label)
    else email.labels.splice(labelIndex, 1)
  }

  data.emails.forEach(email => {
    if (emailIds.includes(email.id)) updateMailLabels(email)
  })

  return [200]
})

// ------------------------------------------------
// GET: Paginate Existing Email
// ------------------------------------------------
mock.onGet('/apps/email/paginate-email').reply(config => {
  const { dir, emailId } = config.params

  const currentEmailIndex = data.emails.findIndex(e => e.id === emailId)

  const newEmailIndex = dir === 'previous' ? currentEmailIndex - 1 : currentEmailIndex + 1

  const newEmail = data.emails[newEmailIndex]

  return newEmail ? [200, newEmail] : [404]
})
