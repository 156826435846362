export default [
  {
    path: '/nguoi-lao-dong/cap-phep-nguoi-lao-dong/danh-sach',
    name: 'workerLicense',
    component: () => import('@/views/management-workers/worker-license/pages/List.vue'),
    meta: {
      parent: 'workerLicense',
      pageTitle: 'Cấp phép người lao động',
      breadcrumb: [
        {
          text: 'Người lao động',
        },
        {
          text: 'Cấp phép người lao động',
          active: true,
        },
      ],
    },
  },
]
