export default {
  // Danh mục thiết bị kiểm định
  API_EQUIPMENT_BUSINESS: 'Equipment/equipments',
  CREATE_EQUIPMENT_BUSINESS: 'Equipment/equipments',
  EDIT_EQUIPMENT_BUSINESS: 'Equipment/equipments',
  IMPORT_FILE_EQUIPMENT_BUSINESS: 'Equipment/import-equipment-from-json',
  DETAIL_EQUIPMENT_BUSINESS: 'Equipment/equipments/',
  DELETE_EQUIPMENT_BUSINESS: 'Equipment/delete-equipments',
  COMBOBOX_EQUIPMENT_BUSINESS: 'IndexEquipment/get-combobox-index-equipment',
}
