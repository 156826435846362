export default [
  {
    path: '/phan-loai-bao-cao/danh-sach',
    component: () => import('@/views/management-business/classify-reports/pages/List.vue'),
    name: 'classify-report',
    meta: {
      pageTitle: 'Phân loại báo cáo',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Phân loại báo cáo',
          to: '/phan-loai-bao-cao/danh-sach',
        },
      ],
    },
  },

  {
    path: '/phan-loai-bao-cao/them-moi/:id',
    component: () => import('@/views/management-business/classify-reports/pages/TableAssignBusiness.vue'),
    name: 'classify-report-add',
    meta: {
      pageTitle: 'Phân loại báo cáo',
      breadcrumb: [
        {
          text: 'Phân loại báo cáo',
          to: '/phan-loai-bao-cao/danh-sach',
        },
        {
          text: 'Gán doanh nghiệp nộp báo cáo',
          active: true,
        },
      ],
    },
  },
]
