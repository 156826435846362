import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import light from '@/views/occupational-safety-health/menu-observation-monitor/store'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from '@/views/management-user/users/store'
import positionJob from '@/views/management-user/position-job/store/index'
import userGroup from '@/views/management-user/User-Group/store/index'
import TrashBin from '@/views/management-user/Trash-Bin/store/index'
import userType from '@/views/management-user/user-type/store/index'
import typeOfBusiness from '@/views/management-business/type-of-business/store/index'
import struct from '@/views/management-user/organization-struct/store/index'
import business from '@/views/management-business/business/store/index'
import businessSector from '@/views/management-business/business-sector/store/index'
import occupation from '@/views/management-workers/occupation-list/store/index'
import worker from '@/views/management-workers/worker/store/index'
import workerPermit from '@/views/management-workers/worker-permit/store/index'
import classify from '@/views/management-workers/health-classification/store/index'
import disease from '@/views/management-workers/occupational-disease/store/index'
import workerDisease from '@/views/management-workers/workers-disease/store/index'
import equipment from '@/views/occupational-safety-health/equipment-management/store/index'
import equipmentBusiness from '@/views/occupational-safety-health/manage-equip/store/index'
import inspector from '@/views/occupational-safety-health/inspector/store/index'
import foster from '@/views/occupational-safety-health/foster/store/index'
import injury from '@/views/occupational-safety-health/classification/store/index'
import archive from '@/views/management-archive-records/category-file/store'
import umployedWorker from '@/views/management-workers/worker-umployed/store'
import performTraining from '@/views/occupational-safety-health/perform-training/store/index'
import environmentalMonitoring from '@/views/occupational-safety-health/environmental-monitoring/store/index'
import affiliatedUnits from '@/views/management-affilliated-units/affiliated-units/store'
import miningAccount from '@/views/management-affilliated-units/Mining-account/store'
import fiterYear from '@/views/management-statistical/store/yearPercent'
import newInspection from '@/views/news/inspection-equipment/store'
import registerJobWorker from '@/views/employment-service/register/store/register'
import employee from '@/views/employment-service/advise/store/employee'
import employer from '@/views/employment-service/advise/store/employer'
import insurance from '@/views/employment-service/insurance/store'
import training from '@/views/employment-service/training/store'
import educationDepartments from '@/views/management-user/education-department/store/index'
import provinceDepartment from '@/views/management-user/province-department/store/index'
import injuryElement from '@/views/occupational-safety-health/injury-element/store'
import accidentStatistical from '@/views/occupational-safety-health/accident-statistical/store'
import collectInforWorker from '@/views/management-collect-infor/collect-infor-worker/store'
import collectforeignWorker from '@/views/management-collect-infor/foreign-workers/store'
import accidentSpecializeReport from '@/views/occupational-safety-health/accident-specialize-report/store'
import notification from '@/views/apps/notifications/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    positionJob,
    userGroup,
    TrashBin,
    userType,
    typeOfBusiness,
    struct,
    business,
    businessSector,
    occupation,
    worker,
    workerPermit,
    classify,
    disease,
    workerDisease,
    equipment,
    equipmentBusiness,
    inspector,
    foster,
    archive,
    injury,
    performTraining,
    umployedWorker,
    affiliatedUnits,
    miningAccount,
    newInspection,
    environmentalMonitoring,
    fiterYear,
    registerJobWorker,
    employee,
    employer,
    insurance,
    training,
    light,
    educationDepartments,
    provinceDepartment,
    injuryElement,
    accidentStatistical,
    collectInforWorker,
    collectforeignWorker,
    accidentSpecializeReport,
    notification,
  },
  strict: process.env.DEV,
})
