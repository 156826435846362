export default {

  // thống kê tai nạn lao động của cơ quan chuyên ngành
  LIST: 'AccidentStatistical/accident-statisticals',
  ADD: 'AccidentStatistical/add-accident-statistical',
  EDIT: 'AccidentStatistical/update-accident-statistical',
  DELETE: 'AccidentStatistical/delete-accident-statistical',
  DETAIL: 'AccidentStatistical/accident-statistical/',

  // Người lao động tai nạn
  LIST_ACCIDENT: 'AccidentStatistical/list-accident-statistical-worker',
  ADD_ACCIDENT: 'AccidentStatistical/add-accident-statistical-worker',
  EDIT_ACCIDENT: 'AccidentStatistical/update-accident-statistical-worker',
  DELETE_ACCIDENT: 'AccidentStatistical/delete-accident-statistical-worker',
  DETAIL_ACCIDENT: 'AccidentStatistical/accidentStatisticalWorker/',

  /// tải file
  TEMPLATE: 'AccidentStatistical/generateTemplate',
  IMPORT: 'AccidentStatistical/import-accident-statistical-worker-from-json',
  EXCEL: 'AccidentStatistical/file-accident-statistical-report',

  // Combobox
  CONTRACT_TYPE: 'Worker/get-combobox-contract-type',
  INJURY_ELEMENT: 'InjuryElement/get-combobox-injury-element',
  INJURY_REASON: 'InjuryReason/get-combobox-injury-reason',
  ACCIDENT_REASON: '/Accident/get-combobox-accident-reason',

  // Tạo sổ thống kê cơ quan chuyên ngành
  REPORT: 'AccidentStatistical/file-accident-statistical-report',

}
