export default [
  {
    path: '/quan-li-don-vi-truc-thuoc/tai-khoan-lien-ket/danh-sach',
    name: 'mining-account',
    component: () => import('@/views/management-affilliated-units/Mining-account/pages/List.vue'),
    meta: {
      parent: 'mining-account',
      pageTitle: 'Tài khoản liên kết',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị trực thuộc',
        },
        {
          text: 'Tài khoản liên kết',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quan-li-don-vi-truc-thuoc/tai-khoan-lien-ket/them-tu-tep',
    name: 'mining-account-import-file',
    component: () => import('@/views/management-affilliated-units/Mining-account/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'mining-account',
      pageTitle: 'Tài khoản liên kết',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị trực thuộc',
          to: '/quan-li-don-vi-truc-thuoc/tai-khoan-lien-ket/danh-sach',
        },
        {
          text: 'Tài khoản liên kết',
          active: true,
        },
      ],
    },
  },
]
