import { $themeBreakpoints } from '@themeConfig'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    dataCountNotify: 0,
    dataConfig: {},
    componentLoading: false,
    isLoading: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    dataCountNotify: state => state.dataCountNotify,
    dataConfig: state => state.dataConfig,
    isLoading: state => state.isLoading,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    DATA_COUNT_NOTIFY(state, data) {
      state.dataCountNotify = data
    },
    DATA_CONFIG(state, data) {
      state.dataConfig = data
    },
    UPDATE_COMPONENT_LOADING(state, val) {
      state.componentLoading = val
    },
    DATA_CONFIG_LOADING(state, val) {
      state.isLoading = val
    },
  },
  actions: {
    async fetchNewNotifiction({ commit }) {
      const { data } = await axiosApiInstance.get('/Message/getCountNewInbox')
      commit('DATA_COUNT_NOTIFY', data)
      return data
    },
    async fetchConfig({ commit }) {
      const { data } = await axiosApiInstance.get('/Home/configuration')
      commit('DATA_CONFIG', data)
      const isLoading = true
      commit('DATA_CONFIG_LOADING', isLoading)
      return data
    },
  },
}
