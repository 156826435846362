/* eslint-disable no-unreachable */
/* eslint-disable global-require */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueLazyload from 'vue-lazyload'
import VueCompositionAPI from '@vue/composition-api'

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// Custome my font
require('@core/assets/fonts/custome/custome/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

const load = require('@/assets/images/loading/Loading.gif')

Vue.config.productionTip = false
Vue.use(VueLazyload, {
  loading: load,
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend'],
})

// eslint-disable-next-line consistent-return
Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
// eslint-disable-next-line consistent-return
Vue.filter('formatDateYears', value => {
  if (value) {
    return moment(String(value)).format('YYYY')
  }
})

// eslint-disable-next-line consistent-return
Vue.filter('formatTime', value => {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.filter('formatSecond', value => {
  if (value === null) { return '-' }
  if (value >= 3600) {
    const hour = Math.floor(value / 3600)
    const minute = Math.floor((value % 3600) / 60)
    const second = value - (hour * 3600 + minute * 60)
    return second === 0 ? `${hour}h${minute}m` : `${hour}h${minute}m${second}s`
  }
  if (value >= 60 && value < 3600) {
    const minute = Math.floor(value / 60)
    const second = value - (minute * 60)
    return second === 0 ? `${minute}m` : `${minute}m${second}s`
  }
  if (value < 60 && value >= 0) {
    return `${value}s`
  }
  return 0
})

Vue.prototype.$lang = 'lang'
Vue.prototype.$language = () => (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi')
Vue.prototype.$toastPosition = 'b-toaster-top-right'
Vue.prototype.$userData = () => JSON.parse(localStorage.getItem('userData'))
Vue.prototype.$serverfile = process.env.VUE_APP_BASE_API
Vue.prototype.$showAllPageLoading = () => {
  document.getElementById('main-loading').style.display = 'block'
}
Vue.prototype.$hideAllPageLoading = () => {
  document.getElementById('main-loading').style.display = 'none'
}
Vue.prototype.$showLoading = () => {
  store.commit('app/UPDATE_COMPONENT_LOADING', true)
}
Vue.prototype.$hideLoading = () => {
  store.commit('app/UPDATE_COMPONENT_LOADING', false)
}

Vue.prototype.$infoPortal = () => {
  // eslint-disable-next-line radix
  const id = parseInt('1k') || 6
  switch (id) {
    case 1:
      return {
        hrefDistrict: 'http://soldtbxh.thainguyen.gov.vn/',
        imgDefaultSquare: require('@/assets/images/banner/thainguyen/upgrade.png'),
        imgDefault: require('@/assets/images/banner/thainguyen/news.png'),
        sideImglogo: require('@/assets/images/pages/thainguyen/logoWhite.svg'),
        province: 'Thái Nguyên',
      }
    case 2:
      return {
        hrefDistrict: 'http://sldtbxh.daknong.gov.vn/',
        imgDefaultSquare: require('@/assets/images/banner/daknong/upgrade.png'),
        imgDefault: require('@/assets/images/banner/daknong/news.png'),
        sideImglogo: require('@/assets/images/pages/daknong/logoWhite.svg'),
        province: 'Đắk Nông',
      }
    case 3:
      return {
        hrefDistrict: 'https://sldtbxh.daklak.gov.vn/',
        imgDefaultSquare: require('@/assets/images/banner/daklak/upgrade.png'),
        imgDefault: require('@/assets/images/banner/daklak/news.png'),
        sideImglogo: require('@/assets/images/pages/daklak/logoWhite.svg'),
        province: 'Đắk Lắk',
      }
    case 4:
      return {
        hrefDistrict: 'https://binhphuoc.gov.vn/vi/sldtbxh/',
        imgDefaultSquare: require('@/assets/images/banner/binhphuoc/upgrade.png'),
        imgDefault: require('@/assets/images/banner/binhphuoc/news.png'),
        sideImglogo: require('@/assets/images/pages/binhphuoc/logoWhite.svg'),
        province: 'Bình Phước',
      }
    case 5:
      return {
        hrefDistrict: 'https://sldtbxh.tiengiang.gov.vn/',
        imgDefaultSquare: require('@/assets/images/banner/tiengiang/upgrade.png'),
        imgDefault: require('@/assets/images/banner/tiengiang/news.png'),
        sideImglogo: require('@/assets/images/pages/tiengiang/logoWhite.svg'),
        province: 'Tiền Giang',
      }
    case 6:
      return {
        hrefDistrict: '#',
        imgDefaultSquare: require('@/assets/images/banner/thainguyen/upgrade.png'),
        imgDefault: require('@/assets/images/banner/thainguyen/news.png'),
        sideImglogo: require('@/assets/images/pages/thainguyen/logoWhite.svg'),
        province: 'Cổng test',
      }
    default:
      return {
        hrefDistrict: '#',
        imgDefaultSquare: require('@/assets/images/banner/thainguyen/upgrade.png'),
        imgDefault: require('@/assets/images/banner/thainguyen/news.png'),
        sideImglogo: require('@/assets/images/pages/thainguyen/logoWhite.svg'),
        province: 'Cổng test',
      }
  }
}

Vue.prototype.$emptyImg = require('@/assets/images/svg/No-data.gif')

new Vue({
  router,
  store,
  i18n,
  moment,
  render: h => h(App),
}).$mount('#app')
