export default [
  {
    path: '/nganh-nghe-kinh-doanh/danh-sach',
    name: 'list-businessSector',
    component: () => import('@/views/management-business/business-sector/pages/List.vue'),
    meta: {
      parent: 'list-businessSector',
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
          // to: '/doanh-nghiep/danh-sach',
        },
        {
          text: 'Ngành nghề kinh doanh',
          to: '/nganh-nghe-kinh-doanh/danh-sach',
        },
      ],
    },
  },
  {
    path: '/nganh-nghe-kinh-doanh/them-tu-file',
    name: 'import-businessSector',
    component: () => import('@/views/management-business/business-sector/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'list-businessSector',
      pageTitle: 'Doanh nghiệp',
      breadcrumb: [
        {
          text: 'Ngành nghề kinh doanh',
          to: '/nganh-nghe-kinh-doanh/danh-sach',
        },
        {
          text: 'Thêm file ngành nghề kinh doanh',
          active: true,
        },
      ],
    },
  },
]
