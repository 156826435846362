/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
const state = () => ({
  yearList: [],
  year: 0,
})
const getters = {
  yearList: state => state.yearList,
  year: state => state.year,
}

const actions = {
  getYear({ commit }) {
    const d = new Date()
    const year = d.getFullYear()
    const dataFilterYear = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      dataFilterYear.push(year - i)
    }
    commit('DATA_LIST_YEAR', dataFilterYear)
    commit('DATA_YEAR', year)
  },
}
const mutations = {
  DATA_LIST_YEAR(state, data) {
    state.yearList = data
  },
  DATA_YEAR(state, data) {
    state.year = data
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
