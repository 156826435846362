export default {
  COLLECT_WORKERS_LIST: 'CollectWorker/collectWorkers',
  DOWNLOAD_FILE: 'CollectWorker/file-collect-worker-report',
  CREATE_EDIT_COLLECT_WORKERS: 'CollectWorker/collectWorkers',
  DETAIL_COLLECT_WORKERS: 'CollectWorker/collectWorkers/',
  DELETE_COLLECT_WORKERS: 'CollectWorker/delete-collect-workers',

  // COMBOBOX
  COMBOBOX_CITY: 'OfficalProfile/provinces',
  COMBOBOX_DISTRICT: 'OfficalProfile/districts/',
  COMBOBOX_WARD: 'OfficalProfile/wards/',
}
