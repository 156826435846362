export default [
  {
    path: '/nguoi-dung/truy-vet-nguoi-dung/danh-sach',
    component: () => import('@/views/management-user/user-tracing/pages/List.vue'),
    name: 'user-tracing',
    meta: {
      pageTitle: 'Truy vết người dùng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Truy vết người dùng',
          active: true,
        },
      ],
    },
  },
]
