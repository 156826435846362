export default {
  // List
  LIST_USER_GROUP: 'GroupUser/groupUsers',
  DOWNLOAD_SAMPLE_USER_GROUP: '/GroupUser/generateTemplate',
  GET_API_EXCEL_USER_GROUP: '/GroupUser/import-group-user-from-json',
  DOWNLOAD_EXCEL_ALL: '/GroupUser/export-excel',
  GET_ALL_COMBOBOX: '/GroupUser/get-combobox-group-user',
  DELETE_USER_GROUP: 'GroupUser/delete-groupUsers',
  // create
  CHECK_CODE_USER_GROUP: 'GroupUser/existCode',
  CHECK_NAME_USER_GROUP: 'GroupUser/existName',
  FETCH_USER_GROUP_TO_ID: '/GroupUser/groupUsers/',
  CREATE_USER_GROUP: 'GroupUser/groupUsers',

}
