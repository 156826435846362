export default [
  // danh sách quản lý tin tức
  {
    path: '/tin-tuc/quan-li-tin-tuc/danh-sach',
    name: 'news-management',
    component: () => import('@/views/news/pages/news-management/List.vue'),
    meta: {
      parent: 'news-management',
      pageTitle: 'Quản lý tin tức',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
        },
        {
          text: 'Quản lý tin tức',
          active: true,
        },
      ],
    },
  },
  // danh sách chủ đề tin tức
  {
    path: '/tin-tuc/chu-de-tin-tuc/danh-sach',
    name: 'news-topic',
    meta: {
      parent: 'news-topic',
      pageTitle: 'Chủ đề tin tức',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
        },
        {
          text: 'Chủ đề tin tức',
          active: true,
        },
      ],
    },
    component: () => import('@/views/news/pages/news-topic/List.vue'),
  },
  // danh sách hòm thư góp ý
  {
    path: '/tin-tuc/hom-thu-gop-y/danh-sach',
    name: 'suggestion-box',
    meta: {
      parent: 'suggestion-box',
      pageTitle: 'Hòm thư góp ý',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
        },
        {
          text: 'Hòm thư góp ý',
          active: true,
        },
      ],
    },
    component: () => import('@/views/news/pages/suggestion-box/pages/List.vue'),
  },
  // danh sách văn bản pháp lý
  {
    path: '/tin-tuc/van-ban-phap-ly/danh-sach',
    name: 'legal-documents',
    meta: {
      parent: 'legal-documents',
      pageTitle: 'Văn bản pháp lý',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
        },
        {
          text: 'Văn bản pháp lý',
          active: true,
        },
      ],
    },
    component: () => import('@/views/news/pages/legal-documents/List.vue'),
  },

  // tạo danh sách quản lý tin tức
  {
    path: '/tin-tuc/quan-li-tin-tuc/tao-moi',
    name: 'news-management-create',
    component: () => import('@/views/news/pages/news-management/Create.vue'),
    meta: {
      parent: 'news-management-create',
      pageTitle: 'Quản lý tin tức',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
          to: '/tin-tuc/quan-li-tin-tuc/danh-sach',
        },
        {
          text: 'Quản lý tin tức',
          to: '/tin-tuc/quan-li-tin-tuc/danh-sach',
        },
        {
          text: 'Thêm mới tin tức',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tin-tuc/quan-li-tin-tuc/chinh-sua/:id',
    name: 'news-management-edit',
    component: () => import('@/views/news/pages/news-management/Create.vue'),
    meta: {
      parent: 'news-management-create',
      pageTitle: 'Quản lý tin tức',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
          to: '/tin-tuc/quan-li-tin-tuc/danh-sach',
        },
        {
          text: 'Quản lý tin tức',
          to: '/tin-tuc/quan-li-tin-tuc/danh-sach',
        },
        {
          text: 'Thêm mới tin tức',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tin-tuc/thiet-bi-kiem/danh-sach',
    name: 'inspection-equipment',
    component: () => import('@/views/news/inspection-equipment/pages/List.vue'),
    meta: {
      parent: 'inspection-equipment',
      pageTitle: 'Thiết bị được kiểm định kiểm tra ATLĐ',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
        },
        {
          text: 'Thiết bị được kiểm định kiểm tra ATLĐ',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tin-tuc/thiet-bi-kiem/danh-sach/them-tu-tep',
    name: 'inspection-equipment-import',
    component: () => import('@/views/news/inspection-equipment/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'inspection-equipment',
      pageTitle: 'Thiết bị được kiểm định kiểm tra ATLĐ',
      breadcrumb: [
        {
          text: 'Cổng thông tin',
          to: '/tin-tuc/thiet-bi-kiem/danh-sach',
        },
        {
          text: 'Thiết bị được kiểm định kiểm tra ATLĐ',
          active: true,
        },
      ],
    },
  },

]
